import {
  Form,
  Input,
  Radio,
  Space,
  Button,
  Cascader,
  Toast,
  Checkbox,
  TextArea,
  Picker,
  Dialog,
  Tag,
  CascadePicker,
} from "antd-mobile";
import {
  ExclamationCircleFill,
  ExclamationCircleOutline,
} from "antd-mobile-icons";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import verify from "../../unit/verify";
import "./report.css";
import Request from "../../unit/request";
import UploaderImg from "../../components/uploadImg/uploadImg";
import SearchSchool from "./components/searchSchool/searchSchool";
import { useCityData } from "../../store";
import SendCode from "../../components/sendCode/sendCode";
import qs from "qs";
import getCloudImg from "../../unit/getCloudImg";
const level = [
  [
    { label: "初级（一、二级监考）", value: "初级" },
    { label: "中级（一、二、三级监考）", value: "中级" },
    { label: "高级（所有等级监考）", value: "高级" },
  ],
];
const Report = () => {
  const { cityData } = useCityData((state) => state);
  const [formData, setFormData] = useState({
    //提供后改过来
    isProtocol: true,
    card: "",
    gender: 1,
  });
  const [orgList, setOrgList] = useState([]);
  const currentOrg = useRef("");
  const getOrgList = (ins_id) => {
    if (currentOrg.current === ins_id) {
      return;
    }
    Request({
      url: "/institution/list",
      method: "post",
      data: {
        ins_id,
      },
    }).then((res) => {
      currentOrg.current = ins_id;
      let arr = res.data || [];
      arr.forEach((v) => {
        v.label = v.name;
        v.value = v.institution_id;
        if (!ins_id) {
          v.children = [];
        }
      });
      if (ins_id) {
        orgList.forEach((v) => {
          if (v.value === ins_id && !v.children?.lgnth) {
            v.children = arr;
          }
        });
        setOrgList([...orgList]);
        return;
      }
      setOrgList([...arr]);
    });
  };
  const navigator = useNavigate();
  const submit = (e) => {
    const params = e;
    if (params.idcardImg) {
      let imgs = params.idcardImg;
      params.idCardFront = imgs[0].serverId;
      params.idCardBack = imgs[0].serverId;
      delete params.idcardImg;
    }
    if (params.certificate) {
      params.certificate = params.certificate[0]?.serverId;
    }
    if (params.photo) {
      params.photo = params.photo[0]?.serverId;
    }
    if (params.address && params.address.length) {
      params.address = params.address.join("|@|");
    }
    if (params.institution_id) {
      params.institution_id = params.institution_id[1];
    }
    let sendCodeInfo = sendCode.current;
    if (sendCodeInfo) {
      params.smsCode = sendCodeInfo.smsCode;
      params.phoneNumber = sendCodeInfo.phoneNum;
    }
    let rule = [
      { key: "name", msg: "请输入姓名" },
      { key: "idcard", msg: "请输入证件号码" },
      { key: "photo", msg: "请上传证件照" },
      { key: "address", msg: "请选择所在城市" },
      { key: "selfIntroduce", msg: "请输入自我介绍" },
      {
        key: "phoneNumber",
        msg: "请输入手机号",
        reg: {
          rule: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
          msg: "请核验手机号格式",
        },
      },
      { key: "smsCode", msg: "请输入短信验证码" },
    ];
    const _verify = verify(params, rule);
    if (_verify) {
      Toast.show({
        content: _verify.msg,
      });
      return;
    }
    Toast.show({
      icon: "success",
      content: "提交成功",
    });
    const url = isReSubmit ? "/user/modify" : "/user/auth";
    Request({
      url: url,
      method: "post",
      data: {
        userLevel: 2,
        detail_id: formData.user_id,
        form: params,
      },
    }).then((res) => {
      Toast.show({
        icon: "success",
        content: "提交成功",
      });
      setTimeout(() => {
        navigator(-1);
      }, 1500);
    });
  };
  const toProtocol = () => {
    if (sessionStorage.isAgree) {
      setAgree(true);
    } else {
      Toast.show({
        icon: <ExclamationCircleOutline />,
        content: "请先阅读并同意测评员协议",
      });
      setTimeout(() => {
        navigator(`/protocol?examiner`);
      }, 2000);
      setAgree(false);
    }
  };
  const getExamineDetail = (id) => {
    Request({
      url: "/examine/detail",
      method: "post",
      data: {
        examine_id: id,
      },
    }).then((res) => {
      const data = res.data;
      if (data.address) {
        data.address = data.address.split("|@|");
      }
      getCloudImg({
        key: "img",
        isFull: true,
        list: [
          {
            img: data.photo,
          },
          {
            img: data.idCardFront,
          },
          {
            img: data.idCardBack,
          },
          {
            img: data.certificate,
          },
        ],
      }).then((list) => {
        Request({
          url: "/institution/list",
          method: "post",
          data: {
            ins_id: "all",
          },
        }).then((res) => {
          let fatherId;
          let insObj = {};
          res.data.forEach((v) => {
            if (!insObj[v.institution_id] && !v.father_ins_id) {
              insObj[v.institution_id] = {
                label: v.name,
                value: v.institution_id,
              };
            }
            if (insObj[v.father_ins_id]) {
              if (insObj[v.father_ins_id].children) {
                insObj[v.father_ins_id].children.push({
                  label: v.name,
                  value: v.institution_id,
                });
              } else {
                insObj[v.father_ins_id].children = [
                  {
                    label: v.name,
                    value: v.institution_id,
                  },
                ];
              }
            }
            if (v.institution_id === data.institution_id) {
              fatherId = v.father_ins_id;
            }
          });
          let insList = [];
          for (let i in insObj) {
            insList.push(insObj[i]);
          }
          setOrgList([...insList]);
          data.photo = [list[0]];
          data.idcardImg = [list[1], list[2]];
          data.certificate = [list[3]];
          data.institution_id = [fatherId, data.institution_id];
          data.rank = data.rank?[data.rank]:[];
          setFormData(data);
          form.current.setFieldsValue(data);
        });
      });
    });
  };
  const examine = (verify) => {
    const url = `/admin/audit/examine`;
    let data = {
      verify,
      user_id: formData.user_id,
      rank: form.current.getFieldValue("rank")[0],
    };
    if (verify === 1 && !data.rank) {
      Toast.show({
        content: "请选择测评员等级",
      });
      return;
    }
    Request({
      url,
      method: "post",
      data,
    }).then((res) => {
      Toast.show({
        icon: "success",
        content: "审核已完成",
      });
      setTimeout(() => {
        navigator(-1);
      }, 1500);
    });
  };
  const formFooter = () => {
    let footer = null;
    const selfFooter = (
      <div className="form-footer">
        <Button
          block
          onClick={async () => {
            const result = await Dialog.confirm({
              header: (
                <ExclamationCircleFill
                  style={{
                    fontSize: 64,
                    color: "var(--adm-color-warning)",
                  }}
                />
              ),
              title: "注意",
              content: isAdmin
                ? "修改信息需要重新审核，是否继续？"
                : "重新认证需要重新提交审核，是否继续？",
            });
            if (result) {
              setIsView(false);
              setIsSelf(false);
              setIsReSubmit(true);
            }
          }}
          color="primary"
          size="large"
        >
          {isAdmin ? "修改信息" : "重新认证"}
        </Button>
      </div>
    );
    if (isSelf || isAdmin) {
      footer = selfFooter;
    }
    if (isAdmin) {
      footer =
        formData.verify === 0 ? (
          <div className="adminBtn">
            <Space direction="vertical" block>
              <Button
                block
                shape="rounded"
                color="primary"
                onClick={() => examine(1)}
              >
                通过
              </Button>
              <Button
                block
                shape="rounded"
                color="danger"
                onClick={() => examine(2)}
              >
                不通过
              </Button>
            </Space>
          </div>
        ) : (
          selfFooter
        );
    }
    if (!isView) {
      footer = (
        <div>
          <Button block type="submit" color="primary" size="large">
            提交
          </Button>
          {/* <Checkbox
            checked={agree || isReSubmit}
            className="checkBoxSubmit"
            onChange={(val) => {
              if (!val) {
                sessionStorage.removeItem("isAgree");
                toProtocol();
              }
              formData.isProtocol = !!val;
            }}
          >
            <div className="reportProtocol">
              已阅读同意
              <div className="examinerLink">《测评员网上报名协议》</div>
            </div>
          </Checkbox> */}
        </div>
      );
    }
    return footer;
  };
  const form = useRef(null);
  const [agree, setAgree] = useState(false);
  const sendCode = useRef(null);
  const location = useLocation();
  const [isReSubmit, setIsReSubmit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSelf, setIsSelf] = useState(false);
  useEffect(() => {
    const { search } = location;
    const { examine_id, type } = qs.parse(search, { ignoreQueryPrefix: true });
    if (type === "admin") {
      setIsAdmin(true);
    }
    if (type === "self") {
      setIsSelf(true);
    }
    if (examine_id) {
      setIsView(true);
      getExamineDetail(examine_id);
      return;
    }
    getOrgList();
    // toProtocol();
  }, []);
  return (
    <div className="report">
      <Form
        ref={form}
        layout="horizontal"
        mode="card"
        onFinish={submit}
        footer={formFooter()}
      >
        {(isSelf || isAdmin) && (
          <Form.Item label="审核状态" required={false}>
            {formData.verify === 0 ? (
              <Tag color="primary" fill="outline">
                审核中
              </Tag>
            ) : formData.verify === 1 ? (
              <Tag color="#87d068" fill="outline">
                审核通过
              </Tag>
            ) : (
              <Tag color="#ff6430" fill="outline">
                审核失败
              </Tag>
            )}
          </Form.Item>
        )}

        <Form.Header>测评员信息</Form.Header>
        <Form.Item label="姓名" name="name" required={false}>
          <Input readOnly={isView} placeholder="请输入" />
        </Form.Item>
        <Form.Item label="性别" initialValue={1} name="gender">
          <Radio.Group disabled={isView}>
            <Space>
              <Radio value={1}>男</Radio>
              <Radio value={2}>女</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="证件类型" required={false} arrow>
          <Input
            readOnly={isView}
            placeholder="请选择证件类型"
            value="中国居民身份证"
            disabled
          />
        </Form.Item>
        <Form.Item label="证件号码" name="idcard" required={false}>
          <Input readOnly={isView} placeholder="请输入" />
        </Form.Item>

        <Form.Item name="idcardImg" label="身份证正反照片" required={false}>
          <UploaderImg
            isView={isView}
            path="examiner"
            text={["正面", "反面"]}
            max={2}
          ></UploaderImg>
        </Form.Item>
        <Form.Item
          label="证件照"
          help="两寸蓝底证件照"
          name="photo"
          required={false}
        >
          <UploaderImg path="examiner" isView={isView}></UploaderImg>
        </Form.Item>
        <Form.Item
          trigger="onConfirm"
          label="所在学校"
          name="school"
          required={false}
          onClick={(e, SearchSchoolRef) => {
            if (isView) {
              return;
            }
            SearchSchoolRef.current?.open();
          }}
        >
          <SearchSchool isExaminer={true} />
        </Form.Item>
        <Form.Item
          label="所属机构"
          name="institution_id"
          required={false}
          trigger="onConfirm"
          onClick={(e, pickerRef) => {
            if (isView) {
              return;
            }
            pickerRef.current?.open();
          }}
        >
          <CascadePicker
            options={orgList}
            onSelect={(val) => {
              const key = val[0];
              if (!key) return;
              getOrgList(key);
            }}
          >
            {(e) => {
              console.log(e, "eeeeeee");
              if (e && e.length && e[1]) {
                return e[1]?.label;
              } else {
                return <div>请选择所属机构（选填）</div>;
              }
            }}
          </CascadePicker>
        </Form.Item>
        <Form.Item
          label="所在城市"
          required={false}
          name="address"
          trigger="onConfirm"
          onClick={(e, cascaderRef) => {
            if (isView) {
              return;
            }
            cascaderRef.current?.open();
          }}
        >
          <Cascader options={cityData}>
            {(e) => {
              return (
                <div>
                  {e.length ? e.map((v) => v.label).join("-") : "请选择城市"}
                </div>
              );
            }}
          </Cascader>
        </Form.Item>
        <Form.Item label="自我介绍" name="selfIntroduce" required={false}>
          <TextArea readOnly={isView} placeholder="请输入自我介绍" />
        </Form.Item>
        <Form.Item
          label="资质"
          help="请上传运动员证、裁判证、毕业证、获奖证书（4选1）"
          name="certificate"
          required={false}
        >
          <UploaderImg path="examiner" isView={isView}></UploaderImg>
        </Form.Item>
        {isSelf && (
          <Form.Subscribe to={["rank"]}>
            {(formData) => (
              <Form.Item
                label="等级"
                help="初级：一、二级测评；中级：一至三级测评；高级：所有等级测评"
                required={false}
              >
                {formData.rank}
              </Form.Item>
            )}
          </Form.Subscribe>
        )}
        {!isView && (
          <>
            <Form.Header></Form.Header>
            <SendCode ref={sendCode} />
          </>
        )}
        {isAdmin && (
          <>
            <Form.Header></Form.Header>
            <Form.Subscribe to={["rank"]}>
              {({ rank }) => (
                <Form.Item
                  label="测评员等级"
                  name="rank"
                  required={false}
                  trigger="onConfirm"
                  onClick={(e, pickerRef) => {
                    if (rank.length && !isReSubmit) {
                      return;
                    }
                    pickerRef.current?.open();
                  }}
                >
                  <Picker columns={level}>
                    {(e) => {
                      return <div>{e[0]?.label || "请选测评员等级"}</div>;
                    }}
                  </Picker>
                </Form.Item>
              )}
            </Form.Subscribe>
          </>
        )}
      </Form>
    </div>
  );
};
export default Report;
