import tcb from "@cloudbase/js-sdk";
const app = tcb.init({
	env: "bjsports-3gjwsnfg45ed42fd"
});

const getCloudImg = (data) => {
	let key = data.key
	const isFull = data.isFull || false
	let alias = data.alias
	let urls = data.list.filter(v => v[key]).map(v => v[key])


	let requestArrary = chunkArray(urls, 50)
	let fileListTotal = []
	// 使用递归函数处理 Promise 链
	const processChunks = (chunks, index) => {
		if (index >= chunks.length) {
			// console.log('gengxin', fileListTotal);

			for (let v of fileListTotal) {
				v.serverId = v.fileID
				data.list.forEach(l => {
					if (l[key] === v.fileID) {
						if (isFull) {
							Object.assign(l, v)
						}
						l[alias || key] = v.tempFileURL
					}
				})
			}
			// fileListTotal.forEach((v) => {

			// });

			return Promise.resolve(data.list);
		}

		const thisUrls = chunks[index];
		return getTmpUrl(thisUrls)
			.then((fileList) => {
				fileListTotal = fileListTotal.concat(fileList);
				return processChunks(chunks, index + 1);
			})
			.catch((error) => {
				console.error(error);
				return data.list;
			});
	};

	return processChunks(requestArrary, 0);
}

function getTmpUrl(thisUrls) {
	return new Promise((r, j) => {
		app.getTempFileURL({
			fileList: thisUrls
		}).then((res) => {

			// console.log('gengxin1',res)
			let { fileList } = res

			r(fileList)

		}, err => {
			r([])
		});
	})
}



function chunkArray(array, chunkSize) {
	return array.reduce((result, _, index) => {
		if (index % chunkSize === 0) {
			result.push(array.slice(index, index + chunkSize));
		}
		return result;
	}, []);
}


export default getCloudImg