import { useEffect, useState, Fragment, useRef } from "react";
import { useLocation } from "react-router-dom";
import copy from 'copy-to-clipboard';

import getCloudImg from "../../unit/getCloudImg";
import Request from "../../unit/request";
import "./examDetail.scss";
import ExamApply from "./components/apply";
import ExamScore from "./components/score";
import ExamAdminScore from "./components/adminScore";
import ExamSurvey from "./components/survey";
import Bottom from "./components/bottom";
import qs from "qs";
import { useLoginStore } from "../../store";
import { Toast, Button } from "antd-mobile";
import upload from "../../unit/uploadImg";

const ExamDetail = () => {

  const { search } = useLocation();
  const obj = qs.parse(search, { ignoreQueryPrefix: true });
  const [detail, setDetail] = useState({
    exam_address: "",
    exam_brife: "",
    exam_category: "",
    exam_date: "",
    exam_detailAddress: "",
    exam_field: "",
    exam_name: "",
    exam_id: "",
    fee: "",
    match_address: "",
    match_brife: "",
    match_date: "",
    match_detailAddress: "",
    match_field: "",
    examiner: [],
    exam_peo_stu: "",
    exam_peo_examine: "",
  });
  const getDetail = () => {
    Request({
      url: "/exam/detail",
      method: "post",
      data: {
        exam_id: obj.exam_id,
      },
    }).then((res) => {
      let resDetail = res.data[0];
      resDetail.examiner = [];
      let arr = [
        {
          img: resDetail.exam_field,
        },
        {
          img: resDetail.match_field,
        },
      ];
      getCloudImg({
        key: "img",
        list: arr,
      }).then((list) => {
        resDetail.exam_field = list[0].img;
        resDetail.match_field = list[1].img;
        setDetail(resDetail);
        getExamMember(resDetail);
      });
    });
  };
  const [isExamMember, setIsExamMember] = useState(false);
  const getExamMember = (resDetail) => {
    if (
      (obj.type === "admin" || !obj.type) &&
      (loginInfo.userInfo.user_level_id === 5 ||
        resDetail.publish_ins_id === loginInfo.userInfo.institution_id)
    ) {
      setIsExamMember(true);
    }
  };
  const { GetUserInfo, loginInfo } = useLoginStore((state) => state);
  const [isExaminer, setIsExaminer] = useState(false);


  const [isAdmin, setIsAdmin] = useState(false);


  useEffect(() => {
    GetUserInfo().then(({ userInfo }) => {
      if (
        userInfo.user_level_id === 2 ||
        (!!userInfo.user_level_id === false &&
          sessionStorage.webRole === "examiner")
      ) {
        setIsExaminer(true);
      }
      getDetail();

      if( userInfo.user_level_id ===5){
        setIsAdmin(true)

      }else{
        setIsAdmin(false)
      }
    });
  }, [loginInfo]);



  const uploadTmpRef = useRef(null);

  function addStudentCert(e) {
    const exam_id = obj.exam_id
    upload({
      files: e.target.files,
      type: "scoreTmp",
      path: `scoreTmp/${exam_id}`,
    }).then((file) => {
      Request({
        url: "/admin/exam/insert",
        method: "post",
        data: {
          exam_id: exam_id,
          fildId: file[0].fileID,
        },
      }).then((res) => {
        console.log('111', res)
        let status = JSON.parse(res.data)
        Toast.show({
          icon: "success",
          content: `成功导入${status.data.status}条数据`,
        });
      });
    });


  }


  return (
    <div className="examDetail">
      <p className="subTitle">测评信息</p>
      <div className="info">
        <div className="examDetailTitle">{detail.exam_name}</div>
        <img src={detail.exam_field} className="banner" alt="" />
        <div className="examDetailInfo">
          <div className="infoItem">
            <div className="infoKey">测评日期：</div>
            <div className="infoValue">{detail.exam_date.split(" ")[0]}</div>
          </div>
          <div className="infoItem">
            <div className="infoKey">测评时间：</div>
            <div className="infoValue">
              {detail.exam_startTime} - {detail.exam_endTime}
            </div>
          </div>
          <div className="infoItem">
            <div className="infoKey">所在城市：</div>
            <div className="infoValue">
              {detail.exam_address.split("|@|").join("-")}
            </div>
          </div>
          <div className="infoItem">
            <div className="infoKey">举办位置：</div>
            <div className="infoValue">{detail.exam_detailAddress}</div>
          </div>
          <div className="infoItem">
            <div className="infoKey">测评科目：</div>
            <div className="infoValue">
              <div className="tag">{detail.exam_category}</div>
            </div>
          </div>
          <div className="infoItem">
            <div className="infoKey">测评等级：</div>
            <div className="infoValue">
              <div className="tag">{detail.exam_rank}级</div>
            </div>
          </div>
          <div className="infoItem">
            <div className="infoKey">测评介绍：</div>
            <div className="infoValue">{detail.exam_brife}</div>
          </div>
        </div>
      </div>

      {detail.match_field && (
        <Fragment>
          <p className="subTitle">赛事信息</p>
          <div className="info">
            <div className="examDetailTitle">{detail.match_name}</div>
            <img src={detail.match_field} className="banner" alt="" />
            <div className="examDetailInfo">
              <div className="infoItem">
                <div className="infoKey">赛事日期：</div>
                <div className="infoValue">
                  {detail.match_date.split(" ")[0]}
                </div>
              </div>
              <div className="infoItem">
                <div className="infoKey">所在城市：</div>
                <div className="infoValue">
                  {detail.match_address.split("|@|").join("-")}
                </div>
              </div>
              <div className="infoItem">
                <div className="infoKey">举办位置：</div>
                <div className="infoValue">{detail.match_detailAddress}</div>
              </div>
              <div className="infoItem">
                <div className="infoKey">赛事介绍：</div>
                <div className="infoValue">{detail.match_brife}</div>
              </div>
            </div>
          </div>
        </Fragment>
      )}



      <div


        style={{ display: isAdmin ? "flex" : "none", justifyContent: "center", alignItems: "center" }}>
        <input
          type="file"
          ref={uploadTmpRef}
          className="uploadTmp"
          id="uploadTmp"
          onChange={(e) => addStudentCert(e)}
        />
        <Button
          block
          className="examBtn"
          shape="rounded"
          color="primary"
          onClick={() => {
            uploadTmpRef.current?.click();
          }}
        >
          补发证书
        </Button>
        {/* <div onClick={() => {
           uploadTmpRef.current?.click();
         }}>
           <p>补发证书</p>
         </div> */}
      </div>
      {/* <div className="downloadCrt" onClick={() => downloadCrt()}>
        批量下载证书
      </div> */}

      {isExamMember && <ExamApply exam_id={obj.exam_id} />}
      {obj.type === "score" && (
        <ExamScore exam_id={obj.exam_id} exam_category={obj.exam_category} />
      )}
      {obj.type === "adminScore" && (
        <>
          <ExamAdminScore
            exam_id={obj.exam_id}
            exam_category={obj.exam_category}
          />

        </>
      )}
      {obj.type === "survey" && <ExamSurvey exam_id={obj.exam_id} />}
      <Bottom
        exam_id={obj.exam_id}
        verify={detail.verify}
        isExaminer={isExaminer}
        fee={detail.fee}
        type={isExamMember ? "admin" : obj.type}
        examSurplus={
          isExaminer
            ? Math.max(detail.exam_peo_examine - detail.enrolled_examine, 0)
            : Math.max(detail.exam_peo_stu - detail.enrolled_stu, 0)
        }
        exam_category={detail.exam_category}
      />
    </div>
  );
};
export default ExamDetail;
