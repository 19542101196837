import { useEffect, useState, Fragment, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import copy from 'copy-to-clipboard';
import Cookies from "js-cookie";

import getCloudImg from "../../unit/getCloudImg";
import Request from "../../unit/request";
import verify from "../../unit/verify";
import "./examInvited.scss";
import ExamApply from "./components/apply";
import ExamScore from "./components/score";
import ExamAdminScore from "./components/adminScore";
import ExamSurvey from "./components/survey";
import UploaderImg from "../../components/uploadImg/uploadImg";
import RoundRectButton from './components/queryButton'

import qs from "qs";
import dayjs from "dayjs";
import { useLoginStore } from "../../store";
import { Toast, Picker, Form, Button, Checkbox, Radio, Space, Input, DatePicker, Upload, Cascader, Dialog, Image } from "antd-mobile";
import { useCityData } from "../../store";
import { ExclamationCircleFill } from "antd-mobile-icons";
import SearchSchool from "./components/searchSchool/searchSchool";
import SendCode from "../../components/sendCode/sendCode";
import { formatDate,formatDate1 } from "../../unit/utils"

const ExamDetail = () => {
  const { search } = useLocation();
  const obj = qs.parse(search, { ignoreQueryPrefix: true });
  const [init, setInit] = useState({});
  const [isProtocol, setIsProtocol] = useState(false);
  const [isSelectExam, setIsSelectExam] = useState(false);
  const [exam_id, setExam_id] = useState();
  const [examStart, setExamStart] = useState(false);
  const [detail, setDetail] = useState({
    exam_address: "",
    exam_brife: "",
    exam_category: "",
    exam_date: "",
    exam_detailAddress: "",
    exam_field: "",
    exam_name: "",
    exam_id: "",
    fee: "",
    match_address: "",
    match_brife: "",
    match_date: "",
    match_detailAddress: "",
    match_field: "",
    examiner: [],
    exam_peo_stu: "",
    exam_peo_examine: "",
  });
  const getDetail = (exam_id) => {
    Request({
      url: "/exam/invitedDetail",
      method: "post",
      data: {
        exam_id: exam_id,
      },
    }).then((res) => {
      let resDetail = res.data[0];
      resDetail.examiner = [];
      let arr = [
        {
          img: resDetail.exam_field,
        },
        {
          img: resDetail.match_field,
        },
      ];
      getCloudImg({
        key: "img",
        list: arr,
      }).then((list) => {
        resDetail.exam_field = list[0].img;
        resDetail.match_field = list[1].img;
        setDetail(resDetail);

      });
    });
  };






  useEffect(() => {
    Cookies.set('requestToken', "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMTAwMDAxMXFxcTIwMjIxMjMxOSIsImlhdCI6MTY3MDA2NTgwM30.UiuK_yxQc6TzjvtMkmCyfWT0VMKM6OjRU85NZSM4Ijc", {
      expires: 1
    })

    getInvitedList();
    // getDetail();
  }, [obj.exam_id]);

  const navigate = useNavigate();
  const toProtocol = () => {
    navigate(`/newsDetail?news_id=6`);
  };
  const { cityData } = useCityData((state) => state);

  const form = useRef(null);
  const sendCode = useRef(null);
  const { type, stu_id } = qs.parse(search, { ignoreQueryPrefix: true });
  // 页面选择
  // 用户查询，通过手机号验证码查询

  const submit = (e) => {
    if (!examStart) {
      Toast.show({
        icon: "error",
        content: `比赛报名暂未开始！`,
      });
      return
    }
    console.log(e)
    const params = e;

    params.materials = JSON.stringify(e.materials)
    params.exam_id = exam_id

    if (!params.exam_id) {
      rule.push({ key: "exam_id", value: true, msg: "请联系管理员" });
    }
    if (params.address && params.address.length) {
      params.address = params.address.join("|@|");
    }
    if (params.photo) {
      params.photo = params.photo[0]?.serverId;
    }

    params.isProtocol = isProtocol;
    let rule = [
      // { key: "unitName", msg: "请输入单位" },
      { key: "name", msg: "请输入姓名" },
      { key: "idcard", msg: "请输入证件号码" },
      { key: "address", msg: "请选择所在城市" },
      { key: "school", msg: "请输入所在学校" }
      // { key: "photo", msg: "请上传证件照" },
    ];

    // if (!stu_id) {
    //   rule.push({ key: "isProtocol", value: true, msg: "请同意报名协议" });
    // }

    let sendCodeInfo = sendCode.current;
    if (sendCodeInfo) {
      params.smsCode = sendCodeInfo.smsCode;
      params.phoneNumber = sendCodeInfo.phoneNum;

      rule.push({ key: "phoneNumber", msg: "请输入手机号" });
      rule.push({ key: "smsCode", msg: "请输入验证码" });
    }

    const _verify = verify(params, rule);
    if (_verify) {
      Toast.show({
        content: _verify.msg,
      });
      return;
    }
    const data = {
      userLevel: 1,
      form: params,
    };
    if (stu_id) {
      data.detail_id = stu_id;
    }

    Request({
      url: type === "edit" ? "/user/modify" : "/exam/invitedApply", // 测评报名
      method: "post",
      data,
    }).then((res) => {
      setTimeout(() => {
        // window.location.reload();
        // navigate("/main/index");
      }, 1500);
      Toast.show({
        icon: "success",
        content: "提交成功",
      });

    });
  };


  const [basicColumns, setbasicColumns] = useState([])

  const [value, setValue] = useState([])

  //获取list
  const getInvitedList = () => {
    Request({
      url: "/exam/invitedList",
      method: "post",
      data: {
        invitedId: obj.invitedId,
      },
    }).then((res) => {
      console.log(res.data)

      setbasicColumns([res.data])

    })
  };




  return (
    <div className="examDetail">
      <RoundRectButton text={'报名查询'} onClick={(val, extend) => {
        navigate("/examInvitedList")
        console.log("gotoList")
      }}></RoundRectButton>
      {/* <button id="fixedButton">点击我</button> */}


      {/* <div class="fancy-divider"></div> */}

      <p className="subTitle">选择组别</p>

      <div className="picker-container">
        <Picker
          className="custom-picker"
          columns={basicColumns}
          value={value}
          onConfirm={(val, extend) => {
            setIsSelectExam(true)
            getDetail(val[0])
            setExam_id(val[0])
            const now = new Date()
            const exam = new Date(extend.items[0].exam_date)
            const format_exam = formatDate1(exam, "datetime")
            console.log(now.getTime(), exam.getTime())

            if (now.getTime() < exam.getTime()) {
              Toast.show({
                icon: "error",
                content: `比赛报名暂未开始！开始时间为：${format_exam}`,
              });
            } else {

              Toast.show({
                icon: "success",
                content: `比赛报名开始！请填写报名信息`,
              });
              setExamStart(true)
            }
            console.log('onSelect', val, extend.items);
          }}
          onSelect={setValue}
        >
          {(items, { open }) => (
            <div className="picker-space">
              <button className="picker-button" onClick={open}>
                选择组别
              </button>
              <span>
                {items.every(item => item === null)
                  ? '未选择'
                  : items.map(item => item?.label ?? '未选择').join(' - ')}
              </span>
            </div>
          )}
        </Picker>
      </div>

      <div class="fancy-divider"></div>

      {/* <hr className="separator" /> */}

      <p className="subTitle">比赛信息</p>{
        !isSelectExam ? <div className="picker-space picker-space1"> <span>请选择比赛</span> </div> :
          <div className="info">
            <div className="examDetailTitle">{detail.exam_name}</div>
            <Image src={detail.exam_field} className="banner" fit="scale-down" alt=""></Image>
            {/* <img src={detail.exam_field} className="banner" alt="" /> */}
            <div className="examDetailInfo">
              {/* <div className="infoItem">
                <div className="infoKey">测评日期：</div>
                <div className="infoValue">{detail.exam_date.split(" ")[0]}</div>
              </div>
              <div className="infoItem">
                <div className="infoKey">测评时间：</div>
                <div className="infoValue">
                  {detail.exam_startTime} - {detail.exam_endTime}
                </div>
              </div> */}
              {/* <div className="infoItem">
                <div className="infoKey">所在城市：</div>
                <div className="infoValue">
                  {detail.exam_address.split("|@|").join("-")}
                </div>
              </div> */}
              {/* <div className="infoItem">
                <div className="infoKey">举办位置：</div>
                <div className="infoValue">{detail.exam_detailAddress}</div>
              </div>
              <div className="infoItem">
                <div className="infoKey">测评科目：</div>
                <div className="infoValue">
                  <div className="tag">{detail.exam_category}</div>
                </div>
              </div>
              <div className="infoItem">
                <div className="infoKey">测评等级：</div>
                <div className="infoValue">
                  <div className="tag">{detail.exam_rank}级</div>
                </div>
              </div> */}
              {/* <div className="infoItem">
                <div className="infoKey">测评介绍：</div>
                <div className="infoValue">{detail.exam_brife}</div>
              </div> */}

              <div className="infoItem">
                <div className="infoKey">报名容量：</div>
                <div className="infoValue">{detail.exam_peo_stu}</div>
              </div>

              <div className="infoItem">
                <div className="infoKey">报名人数：</div>
                <div className="infoValue">{detail.enrolled_stu}</div>
              </div>
            </div>
          </div>

      }

      {detail.match_field && (
        <Fragment>
          <p className="subTitle">赛事信息</p>
          <div className="info">
            <div className="examDetailTitle">{detail.match_name}</div>
            <img src={detail.match_field} className="banner" alt="" />
            <div className="examDetailInfo">
              <div className="infoItem">
                <div className="infoKey">赛事日期：</div>
                <div className="infoValue">
                  {detail.match_date.split(" ")[0]}
                </div>
              </div>
              <div className="infoItem">
                <div className="infoKey">所在城市：</div>
                <div className="infoValue">
                  {detail.match_address.split("|@|").join("-")}
                </div>
              </div>
              <div className="infoItem">
                <div className="infoKey">举办位置：</div>
                <div className="infoValue">{detail.match_detailAddress}</div>
              </div>
              <div className="infoItem">
                <div className="infoKey">赛事介绍：</div>
                <div className="infoValue">{detail.match_brife}</div>
              </div>
            </div>
          </div>
        </Fragment>
      )}

      {/* <div className="downloadCrt" onClick={() => downloadCrt()}>
        批量下载证书
      </div> */}

      {/* {isExamMember && <ExamApply exam_id={obj.exam_id} />} */}
      {obj.type === "score" && (
        <ExamScore exam_id={obj.exam_id} exam_category={obj.exam_category} />
      )}
      {obj.type === "adminScore" && (
        <>
          <ExamAdminScore
            exam_id={obj.exam_id}
            exam_category={obj.exam_category}
          />

        </>
      )}

      {/* <Bottom
        exam_id={obj.exam_id}
        verify={detail.verify}
        isExaminer={isExaminer}
        fee={detail.fee}
        type={isExamMember ? "admin" : obj.type}
        examSurplus={
          isExaminer
            ? Math.max(detail.exam_peo_examine - detail.enrolled_examine, 0)
            : Math.max(detail.exam_peo_stu - detail.enrolled_stu, 0)
        }
        exam_category={detail.exam_category}
      /> */}
      <div class="fancy-divider"></div>

      <Form
        ref={form}
        layout="horizontal"
        mode="card"
        onFinish={submit}
        footer={
          <div>
            {type === "admin" ? (
              <></>
            ) : (
              <>
                <Button block type="submit" color="primary" size="large">
                  {type === "edit" ? "确认修改" : "提交"}
                </Button>

                {/* {type === "edit" ? ("")
                  : (
                    <Checkbox
                      className="checkBoxSubmit"
                      onChange={(val) => {
                        setIsProtocol(!!val);
                      }}
                    >
                      <div className="reportProtocol">
                        已阅读同意
                        <div className="examinerLink" onClick={toProtocol}>
                          《网上报名协议》
                        </div>
                      </div>
                    </Checkbox>
                  )} */}
              </>
            )}
          </div>
        }
      >
        <Form.Header>我要报名</Form.Header>
        {/* <Form.Item
          label="单位名称"
          disabled={type === "edit"}
          name="unitName"
          required={false}
        >
          <Input placeholder="请输入" readOnly={type === "admin"} />
        </Form.Item> */}
        <Form.Item
          label="姓名"
          disabled={type === "edit"}
          name="name"
          required={false}
        >
          <Input placeholder="请输入" readOnly={type === "admin"} />
        </Form.Item>
        <Form.Item
          label="性别"
          disabled={type === "edit"}
          initialValue={1}
          name="gender"
        >
          <Radio.Group disabled={type === "admin"}>
            <Space>
              <Radio value={1}>男</Radio>
              <Radio value={2}>女</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="证件类型"
          disabled={type === "edit"}
          required={false}
          arrow
        >
          <Input placeholder="请选择证件类型" value="中国居民身份证" disabled />
        </Form.Item>
        <Form.Item
          label="证件号码"
          disabled={type === "edit"}
          name="idcard"
          required={false}
        >
          <Input placeholder="请输入证件号码" readOnly={type === "admin"} />
        </Form.Item>
        {/* <Form.Item
          label="生日"
          onClick={(e, datePickerRef) => {
            if (type === "admin") {
              return;
            }
            datePickerRef.current?.open();
          }}
          name="birthday"
          trigger="onConfirm"
          required={false}
        >
          <DatePicker
            title="选择生日"
            min={new Date(new Date().getFullYear() - 50)}
            max={new Date()}
          >
            {(date) => {
              if (date) {
                return dayjs(date).format("YYYY-MM-DD");
              } else {
                return "请选择生日";
              }
            }}
          </DatePicker>
        </Form.Item> */}
        {/* <Form.Item
          label="证件照"
          name="photo"
          help="两寸蓝底证件照"
          required={false}
        >
          <UploaderImg path="student" isView={type === "admin"} />
        </Form.Item> */}

        {/* <Form.Item
          label="上传资料"
          name="materials"
          help="请拍照上传报名表"
          required={false}
        >
          <UploaderImg path={`examInvited/${obj.exam_id}`} isView={type === "admin"} />
        </Form.Item> */}
        <Form.Item
          trigger="onConfirm"
          label="所在学校"
          name="school"
          required={false}
          onClick={(e, SearchSchoolRef) => {
            SearchSchoolRef.current?.open();
          }}
        >
          <SearchSchool />
        </Form.Item>
        <Form.Item
          label="所在城市"
          name="address"
          required={false}
          trigger="onConfirm"
          onClick={(e, cascaderRef) => {
            if (type === "admin") {
              return;
            }
            cascaderRef.current?.open();
          }}
        >
          <Cascader options={cityData}>
            {(e) => {
              if (e && e.length) {
                return e.map((v) => v.label).join("-");
              } else {
                return "请选择所在城市";
              }
            }}
          </Cascader>
        </Form.Item>
        <Form.Header></Form.Header>
        {!type && <SendCode ref={sendCode} />}
      </Form>
    </div>
  );
};
export default ExamDetail;
