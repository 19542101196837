import Request from "./request";
import { Toast } from "antd-mobile";
const pay = async (exam_id, student_id) => {
	const getPaySn = () => {
		return Request({
			url: "/exam/enrollDetail",
			method: "post",
			data: {
				exam_id,
				student_id,
			},
		})
	};
	const setPay = (order_sn) => {
		return new Promise((r, j) => {
			Request({
				url: "/pay/prepay",
				method: "post",
				data: {
					order_sn,
				},
			}).then((res) => {
				let data = res.data;
				window.wx.chooseWXPay({
					timestamp: data.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
					nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
					package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
					signType: data.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
					paySign: data.paySign, // 支付签名
					success: function(res) {
						Toast.show({
							icon: "success",
							content: "支付成功",
						})
						// 支付成功后的回调函数
						r()
					},
					fail: function(err) {
						Toast.show({
							icon: "fail",
							content: "支付失败",
						})
						j()
					}
				});
			});
		})

	};
	const payData = await getPaySn();
	return setPay(payData.data.order_sn);
}
export default pay