import tcb from "@cloudbase/js-sdk";
const app = tcb.init({
	env: "bjsports-3gjwsnfg45ed42fd",
});
const upload = async ({ files = [], path, type = "photo" }) => {
	console.log(files, path, type)
	if (!path) {
		console.error("上传文件缺少path");
		return;
	}
	return new Promise((r, j) => {
		let index = 0;
		let _localFiles = []
		const getServerId = () => {
			if (!files[index]) {
				index = 0
				r(_localFiles)
				return
			}
			app
				.uploadFile({
					// 云存储的路径
					cloudPath: `${path.replace('/', '')}/${type}/${files[index].name}`,
					// 需要上传的文件，File 类型
					filePath: files[index],
				})
				.then((res) => {
					// 返回文件 ID
					_localFiles.push(res)
					index += 1;
					getServerId();
				});
		};
		getServerId();
	});
};

export default upload;
