import { useState, useRef, useImperativeHandle, forwardRef } from "react";
import { Form, Input, Toast } from "antd-mobile";
import verify from "../../unit/verify";
import Request from "../../unit/request";
const SendCode = forwardRef((props, ref) => {
  const isVerify = useRef(false);
  const [verityTime, setVerityTime] = useState(0);
  let Timer;
  const sendCode = () => {
    const phoneCode = {
      phoneNumber: phoneNum,
    };
    const rule = [
      {
        key: "phoneNumber",
        msg: "请输入手机号",
        reg: {
          rule: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
          msg: "请核验手机号格式",
        },
      },
    ];
    const _verify = verify(phoneCode, rule);
    if (_verify) {
      Toast.show({
        content: _verify.msg,
      });
      return;
    }
    setSmsCode("");
    Request({
      url: "/user/sendSms",
      method: "post",
      data: {
        phoneNumber: phoneCode.phoneNumber,
      },
    }).then(
      (res) => {
        if (Timer) {
          clearInterval(Timer);
        }
        isVerify.current = true;
        let second = 59;
        Timer = setInterval(() => {
          second--;
          setVerityTime(second);
          if (second < 1) {
            clearInterval(Timer);
            Timer = null;
          }
        }, 1000);
      },
      (err) => {
        isVerify.current = false;
        Toast.show({
          icon: "error",
          content: "发送失败，请稍后再试",
        });
      }
    );
  };
  const [phoneNum, setPhoneNum] = useState("");
  const [smsCode, setSmsCode] = useState("");
  useImperativeHandle(ref, () => {
    return {
      phoneNum,
      smsCode,
      isVerify: isVerify.current,
    };
  });
  return (
    <div>
      <Form.Item label="手机号" required={false}>
        <Input
          placeholder="请输入手机号"
          value={phoneNum}
          onChange={setPhoneNum}
        />
      </Form.Item>
      <Form.Item
        label="验证码"
        extra={
          verityTime ? (
            <div>{verityTime}s</div>
          ) : (
            <div className="verityBtn" onClick={sendCode}>
              发送验证码
            </div>
          )
        }
      >
        <Input
          placeholder="请输入短信验证码"
          value={smsCode}
          onChange={setSmsCode}
        />
      </Form.Item>
    </div>
  );
});

export default SendCode;
