import "./uploadImg.css";
import upload from "../../unit/uploadImg";
import { useEffect, useState } from "react";
import { useLoading } from "../../store";
import { ImageViewer } from "antd-mobile";
const UploaderImg = (
  props = {
    value: [],
    text: [],
  }
) => {
  const [imgList, setImgList] = useState([]);
  const max = props.max || 1;
  const { open, hide } = useLoading();
  const uploadImg = (el, i) => {
    const files = el?.target?.files;

    if (files.length > 0) {
      let fileArr = []
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const name = file.name.split('.')
        const fileName = `${name[0]}_${new Date().getTime()}`;  // 保存文件名
        const ex = name[1] || '';  // 对文件名进行切割
        fileArr.push(new File([file], `${fileName}${ex && '.'}${ex}`, { type: file.type }))
      }
      open();
      upload({
        files: fileArr,
        path: props.path,
      }).then(
        (res) => {
          hide();
          el.target.files = null;
          el.target.value = "";
          res.forEach((v) => {
            v.img = v.download_url;
            v.serverId = v.fileID;
          });

          let arr = [...imgList];
          arr[i] = {
            ...arr[i],
            ...res[0],
          };
          setImgList(arr);
          props.onChange && props.onChange(arr);
        },
        (err) => {
          hide();
          el.target.files = null;
          el.target.value = "";
        }
      );
    }
  };
  const deleteImg = (v) => {

    let arr = [...imgList];
    for (let i = 0; i < arr.length; i++) {
      if ((arr[i].serverId && arr[i].serverId === v.serverId) || (arr[i].requestId && arr[i].requestId === v.requestId)) {
        arr[i] = {};
        break;
      }
    }
    setImgList(arr);
  };
  const viewImg = (v) => {
    const images = imgList.filter((v) => v.img).map((v) => v.img);
    if (images.length === 1) {
      ImageViewer.show({
        image: images[0],
      });
    } else {
      ImageViewer.Multi.show({
        images: images,
      });
    }
  };
  useEffect(() => {
    let initList = props.value || [];
    if (!Array.isArray(initList)) {
      initList = [];
    }
    for (let i = 0; i < max; i++) {
      if (!initList[i]) {
        initList[i] = {};
      }
      if (props.text && props.text[i]) {
        initList[i].text = props.text[i];
      }
    }
    setImgList(initList);
  }, [props]);
  return (
    <div className="uploaderImgList">
      {imgList.map((v, i) =>
        v.img || v.download_url ? (

          <div key={v.requestId}>
            <div
              className="uploaderImgItem"
              onClick={() => props.isView && viewImg()}
            >
              {!props.isView && (
                <div className="deleteBtn" onClick={() => deleteImg(v)}>
                  <svg className="icon deleteIcon" aria-hidden="true">
                    <use xlinkHref="#icon-shanchu"></use>
                  </svg>
                </div>
              )}
              <img src={v.img || v.download_url} alt="" />
            </div>
            {v.text ? <div className="imgSub">{v.text}</div> : ""}
          </div>
        ) : !props.isView ? (
          <div className="uploadItem">
            <input
              accept="image/*"
              type="file"
              onChange={(e) => uploadImg(e, i)}
              className="uploadInput"
            />
            <div className="uploaderImgItemAdd">
              <svg className="icon addIcon" aria-hidden="true">
                <use xlinkHref="#icon-jiahao1"></use>
              </svg>
            </div>
            {v.text ? <div className="imgSub">{v.text}</div> : ""}
          </div>
        ) : (
          ""
        )
      )}
    </div>
  );
};

export default UploaderImg;
