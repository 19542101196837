import { Toast } from "antd-mobile";
import Request from "./request";

const getImage = (imgs = []) => {
	let arr = []
	imgs.forEach(v => {
		arr.push(new Promise((r, j) => {
			Request({
				url: v.url,
				method: 'get',
				urlType: 'local',
				options: {
					responseType: 'blob'
				}
			}).then((res) => {
				const blob = new Blob([res]);
				const blobUrl = URL.createObjectURL(blob)
				const img = new Image()
				img.src = blobUrl
				img.onload = (e) => {
					v.img = img
					r(img)
				}
			}, () => {
				j(null)
			})
		}))
	})
	return Promise.allSettled(arr)
}

const createCanvas = async ({ name, sex, code, category, rank, time, address, head, callback }) => {
	let _img1 = {
		url: require('../assets/ticket.png')
	}
	let _img2 = {
		url: head
	}
	const [img1, img2] = await getImage([_img1, _img2])
	if (img1.reason) {
		Toast.show('图片获取失败，请重试')
		return
	}
	if (img2.reason) {
		Toast.show('考生照片获取失败，请重试')
		return
	}

	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d")
	canvas.id = "crtCanvas";
	canvas.style.position = "fixed";
	canvas.style.top = "0";
	canvas.style.left = "0";
	canvas.style.opacity = "0";
	canvas.width = "3508"
	canvas.height = "2480"

	ctx.drawImage(_img1.img, 0, 0, 3508, 2480)
	ctx.drawImage(_img2.img, 2548, 900, 410, 627)
	ctx.font = "normal 81px sans-serif";
	ctx.fillText(name, 568, 1011)
	ctx.fillText(sex, 560, 1199)
	ctx.fillText(code, 560, 1387)
	ctx.font = "normal 61px sans-serif";
	ctx.fillText(category, 1248, 1969)
	ctx.fillText(rank, 1698, 1969)
	ctx.fillText(time, 2268, 1969)
	ctx.fillText(address, 1248, 2156)
	document.body.appendChild(canvas);
	callback(canvas.toDataURL('image/jpeg'), "showBtn")
	document.body.removeChild(canvas);

}
export default createCanvas