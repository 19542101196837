import { useNavigate } from 'react-router-dom'
import './newsList.css'
const newsList = [
	{
		title: '北京市朝阳区青少年运动技能定级即将展开北京市朝阳区青少年运动技能定级即将展开',
		date: '2022.09.06',
		source: '北京市羽毛球运动协会'
	}, {
		title: '北京市朝阳区青少年运动技能定级即将展开北京市朝阳区青少年运动技能定级即将展开',
		date: '2022.09.06',
		source: '北京市羽毛球运动协会'
	}, {
		title: '北京市朝阳区青少年运动技能定级即将展开北京市朝阳区青少年运动技能定级即将展开',
		date: '2022.09.06',
		source: '北京市羽毛球运动协会'
	}, {
		title: '北京市朝阳区青少年运动技能定级即将展开北京市朝阳区青少年运动技能定级即将展开',
		date: '2022.09.06',
		source: '北京市羽毛球运动协会'
	}, {
		title: '北京市朝阳区青少年运动技能定级即将展开北京市朝阳区青少年运动技能定级即将展开',
		date: '2022.09.06',
		source: '北京市羽毛球运动协会'
	}, {
		title: '北京市朝阳区青少年运动技能定级即将展开北京市朝阳区青少年运动技能定级即将展开',
		date: '2022.09.06',
		source: '北京市羽毛球运动协会'
	},
]

const NewsList = () => {
	const navigate = useNavigate()
	const toBack = () => {
		navigate(-1)
	}
	const toDetail = () => {
		navigate('/newsDetail')
	}
	return (
		<div className="newsList">
			<p className='backBtn' onClick={toBack}>&lt; 返回首页</p>
			<div className='newsListContent'>
				{
					newsList.map((v, i) => (
						<div className="newsInfoInnerItem" onClick={toDetail} key={i}>
							<p className="newsInfoTitle">{v.title}</p>
							<div className="newsSubInfo">
								<p className="newsInfoSource">{v.source}</p>
								<p className="newsInfoDate">{v.date}</p>
							</div>
						</div>
					))
				}
			</div>
		</div>
	)
}

export default NewsList