import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './unit/calculateSize'
import { CookiesProvider } from 'react-cookie';
import {
	HashRouter as Router
} from "react-router-dom";
import { AliveScope } from 'react-activation'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<CookiesProvider>
		<Router>
			<AliveScope>
				<App />
			</AliveScope>
		</Router>
	</CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
