import { Tag } from "antd-mobile";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoginStore } from "../../store";
import getCloudImg from "../../unit/getCloudImg";
import Request from "../../unit/request";
import qs from "qs";
import "./station.scss";
import NoData from "../../components/noData/noData";

const Station = () => {
  const navigator = useNavigate();
  const { search } = useLocation();
  const { type } = qs.parse(search, { ignoreQueryPrefix: true });
  const { GetUserInfo, loginInfo } = useLoginStore((state) => state);
  const isView = type === 'view'
  const [list, setList] = useState([]);
  const getList = () => {
    Request({
      url: "/admin/ins/centerList",
      method: "post",
      data: {
        ins_id: isView?'':loginInfo.userInfo.institution_id || '',
      },
    }).then((res) => {
      getCloudImg({
        key: "venPhoto1",
        list: res.data,
      }).then((list) => {
        setList(list);
      });
    });
  };
  const toDetail = (v) => {
    navigator(`/institutions?ins_id=${v.institution_id}${isView?'':'&type=parent'}`);
  };
  const rootBtn = (v) => {
	if(isView){
		return
	}
    const { verify } = v;
    let el = null;
    switch (verify) {
      case 0:
        el = (
          <Tag color="primary" fill="outline">
            审核中
          </Tag>
        );
        break;
      case 1:
        el = (
          <Tag color="#87d068" fill="outline">
            审核通过
          </Tag>
        );
        break;
      case 2:
        el = (
          <Tag color="#ff6430" fill="outline">
            审核未通过
          </Tag>
        );
        break;
      default:
        break;
    }
    return el;
  };
  const institutionList = (v) => {
    return (
      <div
        className="authRoleItem"
        onClick={() => {
          toDetail(v);
        }}
      >
        <div className="institution">
          <div className="institutionTitle">{v.name}</div>
          <div className="institutionImg">
            <img src={v.venPhoto1} alt="" />
          </div>
          <div className="institutionData">
            <div className="institutionInfo">
              <span>
                所在城市：{v.address && v.address.replace(/\|@\|/g, "-")}
              </span>
              <span>测评地址：{v.examAddress}</span>
              <span>机构电话：{v.phoneNumber}</span>
            </div>
            {rootBtn(v)}
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    GetUserInfo().then((res) => {
      getList();
    });
  }, []);
  return (
    <div className="stationBox">
      <NoData list={list} text="暂无测评点" />
      {list.map((v) => institutionList(v))}
    </div>
  );
};
export default Station;
