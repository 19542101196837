import {
  Form,
  Input,
  TextArea,
  Button,
  Picker,
  DatePicker,
  Cascader,
  Toast,
} from "antd-mobile";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import verify from "../../../unit/verify";
import { useCityData } from "../../../store";
import "./addCultivate.css";
import Request from "../../../unit/request";
import UploaderImg from "../../../components/uploadImg/uploadImg";
import { useLoginStore } from "../../../store";
const examList = [
  [
    {
      label: "足球",
      value: "足球",
    },
    {
      label: "篮球",
      value: "篮球",
    },
    {
      label: "乒乓球",
      value: "乒乓球",
    },
    {
      label: "羽毛球",
      value: "羽毛球",
    },
    {
      label: "网球",
      value: "网球",
    },
    {
      label: "排球",
      value: "排球",
    },
    {
      label: "游泳",
      value: "游泳",
    },
  ],
];
const AddCultivate = () => {
  const { loginInfo } = useLoginStore((state) => state);
  const { cityData } = useCityData((state) => state);
  const [inputCity, setInputCity] = useState("");
  const [cityVisible, setCityVisible] = useState(false);

  const [examListShow, setExamListShow] = useState(false);
  const [examListInput, setExamListInput] = useState("");

  const [examDateShow, setExamDateShow] = useState(false);
  const [examDateInput, setExamDateInput] = useState([]);
  const [formData, setFormData] = useState({
    category: "",
    address: "",
    detailAddress: "",
    date: "",
  });
  const navigate = useNavigate();
  const submit = (e) => {
    const params = {
      ...formData,
      ...e,
    };
    let rule = [
      { key: "name", msg: "请输入培训名称" },
      { key: "category", msg: "请选择培训类别" },
      { key: "address", msg: "请选择所在城市" },
      { key: "detailAddress", msg: "请输入培训详细地址" },
      { key: "date", msg: "请选择培训日期" },
      { key: "brife", msg: "请输入培训简介" },
      { key: "img", msg: "请上传培训介绍图片" },
      { key: "fee", msg: "请输入报名费用" },
    ];
    const _verify = verify(params, rule);
    if (_verify) {
      Toast.show({
        content: _verify.msg,
      });
      return;
    }
    params.publish_ins_id = loginInfo.userInfo.institution_id;
    Request({
      url: "/admin/train/add",
      method: "post",
      data: {
        form: params,
      },
    }).then((res) => {
      Toast.show({
        icon: "success",
        content: "发布成功",
      });
      setTimeout(() => {
        navigate(-1);
      }, 1500);
    });
  };
  return (
    <div className="report">
      <Form
        layout="horizontal"
        mode="card"
        onFinish={submit}
        footer={
          <div>
            <Button block type="submit" color="primary" size="large">
              发布培训
            </Button>
          </div>
        }
      >
        <Form.Header>培训信息</Form.Header>
        <Form.Item label="培训名称" name="name" required={false}>
          <Input placeholder="请输入培训名称" />
        </Form.Item>
        <Form.Item
          label="培训科目"
          required={false}
          onClick={() => setExamListShow(true)}
        >
          <Picker
            columns={examList}
            visible={examListShow}
            onClose={() => {
              setExamListShow(false);
            }}
            onConfirm={(e, e2) => {
              let { items } = e2;
              formData.category = items[0].value;
              setExamListInput(items[0].label);
            }}
          />
          <Input placeholder="请选择培训科目" value={examListInput} readOnly />
        </Form.Item>
        <Form.Item
          label="所在城市"
          required={false}
          onClick={() => setCityVisible(true)}
        >
          <Cascader
            options={cityData}
            visible={cityVisible}
            onClose={() => {
              setCityVisible(false);
            }}
            onConfirm={(v) => {
              formData.address = v.join("|@|");
              setFormData(formData);
              setInputCity(v.join("-"));
            }}
          />

          <Input placeholder="请选择城市" value={inputCity} readOnly />
        </Form.Item>
        <Form.Item label="培训地址" name="detailAddress" required={false}>
          <Input placeholder="请输入培训详细地址" />
        </Form.Item>
        <Form.Item
          label="培训日期"
          onClick={() => {
            setExamDateShow(true);
          }}
          required={false}
        >
          <Input placeholder="请选择培训日期" value={examDateInput} readOnly />
          <DatePicker
            title="选择培训日期"
            min={new Date()}
            visible={examDateShow}
            onClose={() => {
              setExamDateShow(false);
            }}
            onConfirm={(val) => {
              let date = new Date(val);
              let y = date.getFullYear();
              let m = date.getMonth();
              let d = date.getDate();
              let dateFormat = `${y}-${m >= 9 ? "" : 0}${m + 1}-${
                d >= 9 ? "" : 0
              }${d + 1}`;
              formData.date = dateFormat;
              setExamDateInput(dateFormat);
            }}
          />
        </Form.Item>
        <Form.Item label="培训简介" name="brife" required={false}>
          <TextArea placeholder="请输入培训简介" />
        </Form.Item>
        <Form.Item label="介绍图片" required={false}>
          <UploaderImg
            path="addExam"
            onChange={(img) => {
              formData.img = img[0].serverId;
            }}
          />
        </Form.Item>

        <Form.Header></Form.Header>
        <Form.Item label="培训费用" name="fee" required={false}>
          <Input placeholder="请输入培训费用" type="number" />
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddCultivate;
