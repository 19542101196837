import { useState, useRef, useImperativeHandle, forwardRef } from "react";
import Request from "../../unit/request";
import { SearchBar, CheckList, Popup } from "antd-mobile";
import "./searchSchool.scss";
const SearchSchool = forwardRef((props, ref) => {
  const [active, setActive] = useState("");
  const [visible, setVisible] = useState(false);
  const [school, setSchool] = useState([]);
  const timer = useRef(null);
  const debounce = (v) => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    timer.current = setTimeout(() => {
      getSchool(v);
    }, 300);
  };
  const getSchool = (keyWord) => {
    Request({
      url: "/exam/schoolList",
      method: "post",
      data: {
        type: "serch",
        keyWord,
      },
    }).then((res) => {
      setSchool(res.data?.data || []);
    });
  };
  const open = () => {
    setVisible(true);
    getSchool("");
  };
  const onConfirm = (v) => {
    setVisible(false);
    props.onConfirm(v);
  };
  useImperativeHandle(ref, () => {
    return {
      open,
      onConfirm,
    };
  });
  return (
    <div className="searchSchool">
      {active ||
        props.value ||
        `请选择学校${props.isExaminer ? "（选填）" : ""}`}
      <Popup
        getContainer={null}
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        destroyOnClose
      >
        <div className="searchPopBox">
          <div className="searchTop">
            <SearchBar
              placeholder="请输入搜索的学校名称"
              onChange={(v) => {
                debounce(v);
              }}
            />
          </div>
          <div className="searchList">
            <CheckList
              onChange={(val) => {
                let label =
                  school.find((item) => item._id === val[0])?.UnitCaption || "";
                setActive(label);
                onConfirm(label);
              }}
            >
              {school.map((item) => (
                <CheckList.Item key={item._id} value={item._id}>
                  {item.UnitCaption}
                </CheckList.Item>
              ))}
            </CheckList>
          </div>
        </div>
      </Popup>
    </div>
  );
});

export default SearchSchool;
