import { Dropdown, Radio, Space, Tag, Toast } from "antd-mobile";
import { useRef, useState } from "react";
import { useEffect } from "react";
import Request from "../../../unit/request";
import NoData from "../../../components/noData/noData";
const ApplyExam = (props) => {
  const [type, setType] = useState("student");
  const [apply, setApply] = useState(0);
  const [surveyList, setSurveyList] = useState([]);
  const getData = () => {
    Request({
      url: "/admin/exam/surveyDetail",
      method: "post",
      data: {
        exam_id: props.exam_id,
      },
    }).then((res) => {
      const list = [];
      res.data.examSurvey.forEach((v) => {
        try {
          v.form = JSON.parse(v.form);
          list.push(v);
        } catch (error) {
          console.error(error);
        }
      });
      setSurveyList(list);
    });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="examiner">
      <div className="examinerBlockTitle">测评评论</div>
      <div className="surveyList">
        <NoData list={surveyList} />
        {surveyList.map((v, i) => (
          <div className="surveyItem">
            <div className="surveyItemTime">
              {v.rate_time && v.rate_time.split(" ")[0]}
            </div>
            <div className="surveyItemScore">
              <div className="surveyItemScoreItem">
                测评流程：<span>{v.form.processRate}分</span>
              </div>
            </div>
            <div className="surveyItemContent">{v.form.otherValue}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApplyExam;
