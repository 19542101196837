import { useEffect, useState } from "react";
import Request from "../../../unit/request";
import getCloudImg from "../../../unit/getCloudImg";
import { useNavigate } from "react-router-dom";
import "./myStudent.css";
import NoData from "../../../components/noData/noData";
const MyStudent = () => {
  const navigate = useNavigate();
  const [student, setStudent] = useState([]);
  const getStudent = () => {
    Request({
      url: "/user/myInnerUser",
      method: "get",
    }).then((res) => {
      const list = res.data.myInnerUser.filter((v) => v.userLevel === 1);
      getCloudImg({
        key: "photo",
        alias: "img",
        list,
      }).then((imgList) => {
        setStudent(imgList);
      });
    });
  };
  const toStudentDetail = (v) => {
    navigate(`/report?stu_id=${v.detail_id}&type=edit`);
  };
  useEffect(() => {
    getStudent();
  }, []);
  return (
    <div className="studentList">
      <NoData list={student} text="暂无考生" />
      {student.map((v) => (
        <div className="studentListItem">
          <div
            className="studentItem"
            onClick={() => toStudentDetail(v)}
            key={v.student_id}
          >
            <div className="studentLeft">
              <img src={v.img} alt="" />
            </div>
            <div className="studentRight">
              <p>姓名：{v.name}</p>
              <p>性别：{v.gender === 1 ? "男" : v.gender === 2 ? "女" : "-"}</p>
              <p>生日：{v.birthday && v.birthday.split(" ")[0]}</p>
              <p>身份证：{v.idcard}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MyStudent;
