import "./noData.scss";
const NoData = (props) => {
  return (
    (!props.list || props.list?.length <= 0) && (
      <div className="noData">{props.text || "暂无数据"}</div>
    )
  );
};

export default NoData;
