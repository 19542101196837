const verify = (params = {}, rule = []) => {
	let res = null;
	for (let v of rule) {
		let current;
		if (Array.isArray(v.key)) {
			let key = v.key[0]
			let value = v.key[1]
			let _c = params.hasOwnProperty(key);
			if (_c) {
				current = params[key].hasOwnProperty(value)
			}
		} else {
			current = params.hasOwnProperty(v.key)
		}
		if (current) {
			let item
			if (Array.isArray(v.key)) {
				item = params[v.key[0]][v.key[1]];
			} else {
				item = params[v.key]
			}
			if (!item) {
				res = v;
				break;
			}
			if (Array.isArray(v.child)) {
				for (let i = 0;i < item.length;i++) {
					console.log(item, "	sasdas")
					let _res = verify(item[i], v.child)
					if (_res) {
						_res.msg = _res.msg.replace('${i}', i + 1)
						res = _res;
						break
					}
				}
				if (res) {
					break;
				}
			}
			if (v.hasOwnProperty("type")) {
				if (!(item instanceof v.type)) {
					res = v;
					break;
				}
			}
			if (v.hasOwnProperty("value")) {
				if (item !== v.value) {
					res = v;
					break;
				}
			}

			if (v.reg) {
				if (!v.reg.rule.test(item)) {
					res = v.reg;
					break;
				}
			}
		} else {
			res = v;
			break
		}
	}
	return res;
};
export default verify;