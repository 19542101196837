import "./my.css";
import { Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { useLoginStore } from "../../store";
import { useEffect, useState } from "react";
import Request from "../../unit/request";
import { useRef } from "react";
import { useAliveController } from "react-activation";
const rootLink = [
  //  {
  //    icon: "#icon-jilu",
  //    text: "测评申请审核",
  //    page: "/adminApplyExam",
  //  },
  {
    icon: "#icon-_xuanxiang",
    text: "测评成绩审核",
    page: "/adminAddScore?type=admin",
  },
  {
    icon: "#icon-jiancha",
    text: "发布测评审核",
    page: "/adminExam?type=exam",
  },
  {
    icon: "#icon-shuji",
    text: "发布培训审核",
    noThing: true,
  },
  {
    icon: "#icon-huore",
    text: "发布新闻审核",
    page: "/adminExam?type=news",
  },
  {
    icon: "#icon-xunzhang",
    text: "测评员认证审核",
    page: "/authRole?type=examine",
  },
  {
    icon: "#icon-loufang",
    text: "机构认证审核",
    page: "/authRole?type=institution",
  },
  {
   icon: "#icon-icon-test5",
   text: "证书管理",
   page: "/certManger",
 },
 {
  icon: "#icon-icon-test2",
  text: "学生信息查询",
  page: "/queryData",
},
{
 icon: "#icon-icon-test1",
 text: "学校添加",
 page: "/addSchool",
}
];
const examineeLink = [
  {
    icon: "#icon-tianjia",
    text: "添加考生",
    page: "/report",
    isLogin: true,
  },
  {
    icon: "#icon-nanhai",
    text: "我的考生",
    page: "/myStudent",
    isLogin: true,
  },
  {
    icon: "#icon-icon-test6",
    text: "预约列表",
    isLogin: true,
    page: "/myExamList",
  },
  {
    icon: "#icon-liucheng",
    text: "报名流程",
    iconClass: "size",
    noThing: true,
  },
  {
    icon: "#icon-baoming1",
    text: "报名通道",
    iconClass: "size",
    page: "/main/exam",
  },
  {
    icon: "#icon-chachengji",
    text: "成绩查询",
    isLogin: true,
    iconClass: "size",
    page: "/myExamList?type=search",
  },
  //  {
  //    icon: "#icon-chazhengshu",
  //    text: "证书查询",
  //    iconClass: "size",
  //    page: "/getExamCert",
  //  },
];
const examinerLink = [
  {
    icon: "#icon-kaoguan",
    text: "测评员认证",
    iconClass: "size",
    isLogin: true,
    page: "/examinerReport",
  },
  {
    icon: "#icon-shuru",
    text: "评考申请",
    page: "/main/exam",
    role: [2],
  },
  {
    icon: "#icon-tuandui",
    text: "评考记录",
    page: "/myInvigilating",
    role: [2],
  },
  {
    icon: "#icon-icon-test7",
    text: "培训记录",
    noThing: true,
    role: [2],
  },
];

const My = () => {
  const { ToLogin, loginInfo, outLogin, GetUserInfo } = useLoginStore(
    (state) => state
  );

  const [quickLink, setQuickLink] = useState([
    {
      icon: "#icon-jigou",
      text: "机构认证",
      iconClass: "size",
      isLogin: true,
      page: "/institutions",
      showRole: [3, 4],
    },
    {
      icon: "#icon-icon-test1",
      text: "发布信息",
      page: "/publishExam",
      role: [4, 5],
      showRole: [4, 5],
    },
    {
      icon: "#icon-icon-test2",
      text: "报名数据",
      page: "/enrollManage",
      role: [3, 4, 5],
      showRole: [3, 4, 5],
    },
    {
      icon: "#icon-yuncunchu",
      text: "成绩管理",
      page: "/adminAddScore",
      role: [3, 4, 5],
      showRole: [3, 4, 5],
    },
    {
      icon: "#icon-icon-test4",
      text: "测评点查询",
      page: "/adminStation",
      role: [3],
      showRole: [4],
    },
    {
      icon: "#icon-icon-test3",
      text: "测评员库",
      page: "/examinerStore",
      role: [3, 4, 5],
      showRole: [4, 5],
    },
    {
      icon: "#icon-icon-test5",
      text: "品控",
      page: "/qualityControl",
      role: [3, 4, 5],
      showRole: [3, 4, 5],
    }
  ]);
  const student = (
    <div style={{ marginBottom: "30px" }}>
      <div className="title myQuickLinkTitle">考生管理</div>
      <div className="myQuickLink myQuickLink1">
        {examineeLink.map((v) => (
          <div className="myQuickLinkItem" onClick={() => toPage(v)}>
            <svg className={`icon ${v.iconClass}`} aria-hidden="true">
              <use xlinkHref={v.icon}></use>
            </svg>
            <p>{v.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
  const examiner = (
    <div style={{ marginBottom: "30px" }}>
      <div className="title myQuickLinkTitle">测评员管理</div>
      <div className="myQuickLink myQuickLink2">
        {examinerLink.map((v) => (
          <div className="myQuickLinkItem" onClick={() => toPage(v)}>
            <svg className={`icon ${v.iconClass}`} aria-hidden="true">
              <use xlinkHref={v.icon}></use>
            </svg>
            <p>{v.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
  const institutions = (
    <div style={{ marginBottom: "30px" }}>
      <div className="title myQuickLinkTitle">机构管理</div>
      <div className="myQuickLink">
        {quickLink.map((v) => {
          if (
            sessionStorage.webRole === "institutions" ||
            v.showRole.includes(loginInfo?.userInfo?.user_level_id || "")
          ) {
            return (
              <div className="myQuickLinkItem" onClick={() => toPage(v)}>
                <svg className={`icon ${v.iconClass}`} aria-hidden="true">
                  <use xlinkHref={v.icon}></use>
                </svg>
                <p>{v.text}</p>
              </div>
            );
          } else {
            return <></>;
          }
        })}
      </div>
    </div>
  );

  const [localRole, setLocalRole] = useState("");

  const navigate = useNavigate();
  const toPage = (v) => {
    if (v.role) {
      if (roleVerify.current === 0) {
        Toast.show({
          content: "角色认证完成后开放",
        });
        return;
      }
      if (roleVerify.current === 2) {
        Toast.show({
          content: "角色认证失败，请重新认证完成后开放",
        });
        return;
      }
    }
    if (v.noThing) {
      Toast.show({
        content: "暂无数据",
      });
      return;
    }
    if (!v.isLogin || loginInfo.isLogin) {
      if (v.page === "/institutions") {
        if (
          loginInfo.userInfo.user_level_id === 3 ||
          loginInfo.userInfo.user_level_id === 4
        ) {
          v.page = `${v.page}?ins_id=${loginInfo.userInfo.institution_id}&type=self`;
        }
      }
      navigate(v.page);
    } else {
      ToLogin(`/#${v.page}`);
    }
  };

  const exit = () => {
    outLogin();
  };

  let role = "";
  const roleVerify = useRef(0);
  switch (loginInfo.userInfo.user_level_id) {
    case 5:
      role = "组委会";
      break;
    case 4:
      role = "机构管理员";
      break;
    case 3:
      role = "测评中心管理员";
      break;
    case 2:
      role = "测评员";
      break;
    case 1:
      role = "考生";
      break;
    default:
      role = "";
  }

  const isGetInfo = useRef(false);
  const quickArea = () => {
    if (loginInfo.userInfo.user_level_id === 2 || localRole === "examiner") {
      !isGetInfo.current && getExaminerInfo();
      return examiner;
    }
    if (
      sessionStorage.webRole === "institutions" ||
      loginInfo.userInfo.user_level_id === 4 ||
      loginInfo.userInfo.user_level_id === 3 ||
      loginInfo.userInfo.user_level_id === 5
    ) {
      sessionStorage.webRole !== "institutions" &&
        !isGetInfo.current &&
        getInsInfo(loginInfo.userInfo.institution_id);
      return institutions;
    }
    if (loginInfo.userInfo.user_level_id !== 5) {
      return student;
    }
  };
  const rootArea = () => {
    if (loginInfo.userInfo.user_level_id === 5) {
      return (
        <div style={{ marginBottom: "30px" }}>
          <div className="myQuickLink myQuickLink1">
            {rootLink.map((v) => (
              <div
                className="myQuickLinkItem"
                key={v.text}
                onClick={() => toPage(v)}
              >
                <svg className={`icon ${v.iconClass}`} aria-hidden="true">
                  <use xlinkHref={v.icon}></use>
                </svg>
                <p>{v.text}</p>
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return;
    }
  };
  
  const getExaminerInfo = () => {
    isGetInfo.current = true;
    Request({
      url: "/user/myInnerUser",
      method: "get",
    }).then((res) => {
      const list = res.data.myInnerUser.filter((v) => v.userLevel === 2);
      examinerLink[0].page = `/examinerReport?examine_id=${list[0].detail_id}&type=self`;
      if (list.length > 0) {
        Request({
          url: "/examine/detail",
          method: "post",
          data: {
            examine_id: list[0].detail_id,
          },
        }).then((res2) => {
          roleVerify.current = res2.data.verify;
          isGetInfo.current = false;
        });
      }
    });
  };
  const getInsInfo = (id) => {
    if (loginInfo.userInfo.user_level_id === 5) {
      roleVerify.current = 1;
      return;
    }
    isGetInfo.current = true;
    Request({
      url: "/institution/detail",
      method: "post",
      data: {
        institution_id: id,
      },
    }).then((res) => {
      roleVerify.current = res.data.verify;
      quickLink[0].text = "我的机构";
      setQuickLink([...quickLink]);
    });
  };
  const { clear } = useAliveController();
  useEffect(() => {
    clear();
    sessionStorage.removeItem("isAgree");
    GetUserInfo(true).then(
      (login) => {
        if (!!login.userInfo.user_level_id === false) {
          if (sessionStorage.webRole) {
            setLocalRole(sessionStorage.webRole);
          }
        }
      },
      (err) => {
        console.error(err);
      }
    );
    return () => {
      isGetInfo.current = false;
    };
  }, [GetUserInfo]);
  return (
    <div className="my">
      <div className="myBg"></div>
      <div className="myCard">
        {loginInfo.isLogin ? (
          <div
            className="userInfo"
            onClick={() => (loginInfo.isLogin ? "" : ToLogin(`/#/main/my`))}
          >
            <div className="head">
              <img src={loginInfo.userInfo.avatar} alt="" />
            </div>
            <div className="name">{loginInfo.userInfo.nickname}</div>
            {role && (
              <div className="tags">
                <div className="tagItem">{role}</div>
              </div>
            )}
          </div>
        ) : (
          <div
            className="notLogin"
            onClick={() => (loginInfo.isLogin ? "" : ToLogin(`/#/main/my`))}
          >
            <div className="head">
              <svg className="icon" aria-hidden="true">
                <use xlinkHref="#icon-yonghu"></use>
              </svg>
            </div>
            <div className="name">请登录</div>
          </div>
        )}
      </div>
      {quickArea()}
      {rootArea()}
      {loginInfo.isLogin && (
        <div className="exit" onClick={exit}>
          退出登录
        </div>
      )}
    </div>
  );
};
export default My;
