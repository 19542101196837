// 导出appid和secret，这些通常是固定的，不随环境变化
export const appid = 'wx8214df45cf195e9e';
export const secret = 'f9088c3064ab1d05e5e45674b74e064d';

// 根据环境变量动态设置baseHost
let baseHost;
if (process.env.NODE_ENV === 'development') {
    // 开发环境下baseHost的值
    // baseHost = 'https://localhost';
    baseHost = 'https://mp.bjsssst.com';

} else if (process.env.NODE_ENV === 'production') {
    // 生产环境下baseHost的值
    baseHost = 'https://mp.bjsssst.com';
}

// 导出baseHost，注意这里不能直接使用export，因为它是根据条件变化的
export { baseHost };

