import { Toast } from "antd-mobile";
import Request from "./request";

const getImage = (imgs = []) => {
	let arr = []
	imgs.forEach(v => {
		arr.push(new Promise((r, j) => {
			Request({
				url: v.url,
				method: 'get',
				urlType: 'local',
				options: {
					responseType: 'blob'
				}
			}).then((res) => {
				const blob = new Blob([res]);
				const blobUrl = URL.createObjectURL(blob)
				const img = new Image()
				img.crossOrigin = ''
				img.src = blobUrl
				img.onload = (e) => {
					v.img = img
					r(img)
				}
			}, () => {
				j(null)
			})
		}))
	})
	return Promise.allSettled(arr)
}

const createCanvas = async (callback, name, idcard, level, sn, head,category,exam_date) => {
	let _img1 = {
		url: require('../assets/crt.jpg')
	}
	let _img2 = {
		url: head
	}
	const [img1, img2] = await getImage([_img1, _img2])
	if (img1.reason) {
		Toast.show('图片获取失败，请重试')
		return
	}
	if (img2.reason) {
		Toast.show('考生照片获取失败，请重试')
		return
	}

	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d")
	canvas.id = "crtCanvas";
	canvas.style.position = "fixed";
	canvas.style.top = "0";
	canvas.style.left = "0";
	canvas.style.opacity = "0";
	canvas.width = "3508"
	canvas.height = "2480"

	ctx.drawImage(_img1.img, 0, 0, 3508, 2480)
	ctx.drawImage(_img2.img, 2506, 713, 496, 666)
	ctx.font = "normal 51px sans-serif";
	ctx.fillText(name, 668, 925-138)
	ctx.fillText(idcard, 668, 1049-138)
	ctx.fillText(`${category}${level}级`, 668, 1179-138)
	ctx.fillText(sn, 668, 1309-138)
	ctx.fillText(exam_date.slice(0, 10),668,1309)
	document.body.appendChild(canvas);
	callback(canvas.toDataURL('image/jpeg'))
	document.body.removeChild(canvas);

}
export default createCanvas