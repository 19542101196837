import "./examinerStore.css";
import { Tabs, Tag, Dialog, Popup } from "antd-mobile";
import Request from "../../unit/request";
import { useEffect, useState } from "react";
import { useLoginStore } from "../../store";
import { useNavigate } from "react-router-dom";
import getCloudImg from "../../unit/getCloudImg";
import NoData from "../../components/noData/noData";
import { ExclamationCircleFill } from "antd-mobile-icons";
const ExaminerStore = () => {
  const navigator = useNavigate();
  const { GetUserInfo, loginInfo } = useLoginStore((state) => state);
  const [activeList, setActiveList] = useState([]);
  const getExaminerList = () => {
    Request({
      url: "/examine/list",
      method: "post",
      data: {
        ins_id: loginInfo.userInfo.institution_id,
      },
    }).then(
      (res) => {
        getCloudImg({
          key: "photo",
          list: res.data,
        }).then((list) => {
          setActiveList(list);
        });
      },
      (err) => {
        setActiveList([]);
      }
    );
  };
  const toExamineDetail = (v) => {
    navigator(`/examinerReport?examine_id=${v.detail_id}&type=self`);
  };
  const rootBtn = (v) => {
    const { verify } = v;
    let el = null;
    switch (verify) {
      case 0:
        el = (
          <Tag color="primary" fill="outline">
            审核中
          </Tag>
        );
        break;
      case 1:
        el = (
          <Tag color="#87d068" fill="outline">
            审核通过
          </Tag>
        );
        break;
      case 2:
        el = (
          <Tag color="#ff6430" fill="outline">
            审核未通过
          </Tag>
        );
        break;
      default:
        break;
    }
    return el;
  };
  const examineList = (v) => {
    return (
      <div className="authRoleItem" onClick={() => toExamineDetail(v)}>
        <div className="examine">
          <div className="examineImg">
            <img src={v.photo} alt="" />
          </div>
          <div className="examineData">
            <div className="examineInfo">
              <div className="examineTitle">{v.name}</div>
              <div className="examineInfoItem">
                <span>
                  性别：{v.gender === 1 ? "男" : v.gender === 2 ? "女" : "未知"}
                </span>
                <span>学校：{v.school}</span>
                <span>电话：{v.phoneNumber}</span>
                <p>
                  评考记录：
                  <span
                    className="clickLine"
                    onClick={(e) => {
                      e.stopPropagation();
                      showList(v);
                    }}
                  >
                    点击查看
                  </span>
                </p>
              </div>
            </div>

            {rootBtn(v)}
          </div>
        </div>
      </div>
    );
  };
  const activeChange = (e) => {
    if (e === "1") {
      getExaminerList();
    } else {
      setActiveList([]);
    }
  };
  const [popupShow, setPopupShow] = useState(false);
  const [showListData, setShowListData] = useState([]);
  const showList = (v) => {
    Request({
      url: "/admin/examine/enrollDetail",
      method: "post",
      data: {
        user_id: v.user_id,
      },
    }).then((res) => {
      getCloudImg({
        list: res.data,
        key: "photo",
      }).then((list) => {
        setShowListData(list);
        setPopupShow(true);
      });
    });
  };
  const toDetail = (e) => {
    let url = `/examDetail?exam_id=${e.exam_id}`;
    navigator(url);
  };
  useEffect(() => {
    GetUserInfo().then(() => {
      getExaminerList();
    });
  }, []);
  return (
    <div className="examinerStore">
      <div className="topTabs">
        <Tabs onChange={activeChange}>
          <Tabs.Tab title="测评员列表" key="1" />
          <Tabs.Tab title="培训统计" key="2" />
        </Tabs>
      </div>

      <div className="examinerList">
        <NoData list={activeList} />
        {activeList.map((v) => examineList(v))}
      </div>
      <Popup
        visible={popupShow}
        bodyStyle={{
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          minHeight: "30vh",
          maxHeight: "70vh",
          overflow: "auto",
        }}
        onMaskClick={() => {
          setPopupShow(false);
        }}
      >
        <div className="examBox">
          {showListData.map((v) => (
            <div className="examItem">
              {/*<img src={v.photo} alt="" />*/}
              <div className="examInner">
                <div className="examInfo">
                  <p className="examTitle">{v.exam_name}</p>
                  <Tag color="primary" fill="outline">
                    {v.exam_category}
                    {v.exam_rank && `${v.exam_rank}级`}
                  </Tag>
                  {/*<p className="examAddress">
                    <svg className="icon" aria-hidden="true">
                      <use xlinkHref="#icon-weizhi1"></use>
                    </svg>
                    {v.exam_detailAddress}
                  </p>*/}
                  <p className="examDate">
                    <svg className="icon" aria-hidden="true">
                      <use xlinkHref="#icon-shijian"></use>
                    </svg>
                    {v.exam_date.split(" ")[0]}
                  </p>
                </div>
                <div className="examBtn" onClick={() => toDetail(v)}>
                  <p>查看</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Popup>
    </div>
  );
};

export default ExaminerStore;
