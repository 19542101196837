import create from 'zustand'
import { getToken, refreshToken, getUserInfo, ToLogin1, login, getLoginInfo } from '../unit/login'
import qs from 'qs'
import Cookies from 'js-cookie'
import cityData from '../unit/cityData'
export const useLoginStore = create((set, get) => ({
	loginInfo: {
		code: '',
		userInfo: {},
		isLogin: false
	},
	ToLogin: (url) => {
		
		ToLogin1(url || `/${window.location.hash.split("?")[0]}`)
	},
	outLogin: () => {
		Cookies.remove('token')
		Cookies.remove('openid')
		Cookies.remove('requestToken')
		Cookies.remove('refreshToken')
		sessionStorage.removeItem('loginInfo')
		sessionStorage.removeItem('loginHref')
		set(() => ({
			loginInfo: {
				code: '',
				userInfo: {},
				isLogin: false
			}
		}))
	},
	refreshUserInfo: async () => {
		try {
			const loginInfo = JSON.parse(sessionStorage.loginInfo)
			if (loginInfo.isLogin === false) {
				return
			}
			loginInfo.userInfo = await getUserInfo()
			loginInfo.isLogin = true
			sessionStorage.loginInfo = JSON.stringify(loginInfo)
			set(() => ({ loginInfo }))
		} catch (error) {

		}
	},
	GetUserInfo: async (refres) => {
		let { loginInfo } = get()
		const location = window.location.search
		let sessionLogin = null
		try {
			sessionLogin = JSON.parse(sessionStorage.loginInfo)
		} catch (error) {
			sessionLogin = null
		}
		try {
			if (!loginInfo.isLogin) {
				if (sessionLogin && sessionLogin.isLogin) {
					loginInfo = sessionLogin
				} else {
					let Token = Cookies.get('token')
					let OpenId = Cookies.get('openid')
					const RefreshToken = Cookies.get('refreshToken')
					if (!Token || !OpenId) {
						if (RefreshToken) {
							[Token, OpenId] = await refreshToken(RefreshToken)
						} else {
							sessionStorage.removeItem('loginInfo')
							const locationParams = qs.parse(location.split('?')[1] || '') || {
								code: ''
							}
							if (locationParams.code) { // 当前是否是redirect_uri
								[Token, OpenId] = await getToken(locationParams.code)
								loginInfo.code = locationParams.code
							}
						}
					}
					if (Token && OpenId) {
						const loginParams = await getLoginInfo(Token, OpenId)
						await login({ ...loginInfo, userInfo: loginParams })
						loginInfo.userInfo = await getUserInfo()
						let href = window.location.href.replace(/\?(.*)#/, '#')
						loginInfo.isLogin = true
						if (!!loginInfo.userInfo.user_level_id === true) {
							sessionStorage.webRole = ''
						}
						sessionStorage.loginInfo = JSON.stringify(loginInfo)
						window.history.replaceState("", "", href)
						window.location.replace(href)
					}
				}
			}
		} catch (error) {

			console.error('登陆流程出现错误', error)
			const { outLogin } = get()
			loginInfo.isLogin = false
			outLogin()
			throw error

		} finally {

			set(() => ({ loginInfo }))
			if (refres) {
				await get().refreshUserInfo()
			}

		}

		return loginInfo

	}
}))

export const useLoadingMask = create((set, get) => ({
	visible: false,
	show() {
		set(() => ({ visible: true }))
	},
	close() {
		set(() => ({ visible: false }))
	},
}))

export const useCityData = create((set, get) => ({
	cityData: [],
	getCityData: () => {
		if (get().cityData.length > 0) {
			return
		}
		cityData().then(res => {
			set(() => ({ cityData: res }))
		})
	}
}))

export const useLoading = create((set) => ({
	show: false,
	open: () => {
		set(() => ({ show: true }))
	},
	hide: () => {
		set(() => ({ show: false }))
	}
}))