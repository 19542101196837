import "./exam.css";
import Header from "../../components/header/header";
import { useNavigate } from "react-router-dom";
import Request from "../../unit/request";
import { useEffect, useState } from "react";
import getCloudImg from "../../unit/getCloudImg";
import { useLoginStore } from "../../store";
import NoData from "../../components/noData/noData";
import { Tag } from "antd-mobile";

const Exam = () => {
  const [examList, setExamList] = useState([]);
  const filterChange = (params) => {
    getExamList(params);
  };
  const getExamList = (params = {}) => {
    params.enroll = true;
    Request({
      url: "/exam/list",
      method: "post",
      data: params,
    }).then((res) => {
      getCloudImg({
        key: "img",
        list: res.data,
      }).then((list) => {
        setExamList(list);
      });
    });
  };
  const navigate = useNavigate();
  const toDetail = (e) => {
    navigate(`/examDetail?exam_id=${e.exam_id}`);
  };
  const { GetUserInfo, loginInfo, ToLogin } = useLoginStore((state) => state);
  const [isExaminer, setIsExaminer] = useState(false);
  const renderExamBtnInfo = (item) => {
    let max = isExaminer
      ? Math.max(item.exam_peo_examine - item.enrolled_examine, 0)
      : Math.max(item.exam_peo_stu - item.enrolled_stu, 0);
    return (
      <div className="examBtnInfo">
        {max > 0 ? (
          <div className="examBtn" onClick={() => toDetail(item)}>
            <p>{isExaminer ? "申请监考" : "立即报名"}</p>
          </div>
        ) : (
          <div className="examBtn" style={{ background: "#ccc" }}>
            停止报名
          </div>
        )}

        <span className="examSurplus">剩余名额：{max}</span>
      </div>
    );
  };

  useEffect(() => {
    GetUserInfo().then(() => {
      if (
        loginInfo.userInfo.user_level_id === 2 ||
        (!!loginInfo.userInfo.user_level_id === false &&
          sessionStorage.webRole === "examiner")
      ) {
        setIsExaminer(true);
      }
      getExamList();
    });
  }, []);
  return loginInfo.isLogin ? (
    <div>
      <Header drop={true} onChange={filterChange} />
      <div className="examBox">
        <NoData list={examList} />
        {examList.map((v) => (
          <div className="examItem">
            <img src={v.img} alt="" />
            <div className="examInner">
              <div className="examInfo">
                <p className="examTitle">{v.exam_name}</p>
                <Tag color="primary" fill="outline">
                  {v.exam_category}
                  {v.exam_rank && `${v.exam_rank}级`}
                </Tag>
                <p className="examAddress">
                  <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#icon-weizhi1"></use>
                  </svg>
                  {v.exam_detailAddress}
                </p>
                <p className="examDate">
                  <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#icon-shijian"></use>
                  </svg>
                  {v.exam_date.split(" ")[0]}
                </p>
              </div>
              {renderExamBtnInfo(v)}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className="isLoginBox">
      <img src={require("../../assets/isLogin.svg").default} alt="" srcset="" />
      <p className="isLoginTitle">您还没有登录～</p>
      <div className="isLoginBtn" onClick={() => ToLogin("/#/main/exam")}>
        一键微信登录
      </div>
    </div>
  );
};

export default Exam;
