import { Image,Space, Tag, Popover, Toast } from "antd-mobile";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import getCloudImg from "../../unit/getCloudImg";
import Request from "../../unit/request";
import qs from "qs";
import "./adminAddScore.scss";
import NoData from "../../components/noData/noData";


const AdminAddScore = () => {
  const [examList, setExamList] = useState([]);
  const navigate = useNavigate();
  
  const getList = () => {
    Request({
      url: "/admin/exam/enrollList",
      method: "post",
    }).then(
      (res) => {
        getCloudImg({
          key: "exam_field",
          list: res.data,
        }).then((list) => {

          console.log(list)
          setExamList(list);
        });
      },
      (err) => {
        setExamList([]);
      }
    );
  };
  const { search } = useLocation();
  const { type } = qs.parse(search, { ignoreQueryPrefix: true });
  const toDetail = (v) => {
    navigate(
      `/examDetail?exam_id=${v.exam_id}&type=${
        type === "admin" ? "adminScore" : "score"
      }`
    );
  };
  useEffect(() => {
    getList();
  }, []);
  return (
    <div className="adminApplyExam">
      <div className="examBox">
        <NoData list={examList} />
        {examList.map((v) => (
          <div className="examItem">
            <Image src={v.exam_field} alt="" lazy/>
            <div className="examInner">
              <div className="examInfo">
                <p className="examTitle">{v.exam_name}</p>
                <Space>
                  <Tag color="primary" fill="outline">
                    {v.exam_category}
                    {v.exam_rank && `${v.exam_rank}级`}
                  </Tag>
                  <Tag color="#87d068" fill="outline">
                    参考学生{v.stu_Num || 0}人
                  </Tag>
                </Space>

                <p className="examAddress">
                  <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#icon-weizhi1"></use>
                  </svg>
                  {v.exam_address && v.exam_address.split("|@|").join("-")}
                </p>
                <p className="examDate">
                  <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#icon-shijian"></use>
                  </svg>
                  {v.exam_date.split(" ")[0]}
                </p>
              </div>
              <div className="examBtnBox">
                {v.verify === 1 ? (
                  <div className="examBtn" onClick={() => toDetail(v)}>
                    <p>{type === "admin" ? "审核" : "打分"}</p>
                  </div>
                ) : v.verify === 0 ? (
                  <div className="examBtn" style={{ background: "#ccc" }}>
                    <p>该测评审核中</p>
                  </div>
                ) : (
                  <div className="examBtn" style={{ background: "#ccc" }}>
                    <p>该测评审核未通过</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminAddScore;
