import { useEffect, useState, Fragment, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import copy from 'copy-to-clipboard';
import Cookies from "js-cookie";

import Request from "../../unit/request";
import verify from "../../unit/verify";
import "./addSchool.scss";


import qs from "qs";

import { Toast, Picker, Form, Button, Checkbox, Radio, Space, Input, DatePicker, Upload, Cascader, Dialog, Image } from "antd-mobile";
import { useCityData } from "../../store";
import SearchSchool from "../../components/searchSchool/searchSchool";

const ExamDetail = () => {
  const navigate = useNavigate();
  const form = useRef(null);
  const type = useRef(null);
  const { cityData } = useCityData((state) => state);

  const submit = (e) => {
    console.log(e)
    const params = e;


    if (params.address && params.address.length) {
      params.address = params.address.join("|@|");
    }
 

    let rule = [
      // { key: "unitName", msg: "请输入单位" },
      { key: "address", msg: "请选择所在城市" },
      { key: "school", msg: "请输入所在学校" }
      // { key: "photo", msg: "请上传证件照" },
    ];


    const _verify = verify(params, rule);

    if (_verify) {
      Toast.show({
        content: _verify.msg,
      });
      return;
    }
    const data = {
      userLevel: 1,
      form: params,
    };


    Request({
      url: "/admin/school/add", // 测评报名
      method: "post",
      data,
    }).then((res) => {
      setTimeout(() => {
        // window.location.reload();
        // navigate("/main/index");
      }, 1500);
      Toast.show({
        icon: "success",
        content: "提交成功",
      });

    }).catch(e=>{
      Toast.show({
        icon: "error",
        content: "提交失败",
      });
    });
  };



return(
  <div>
  {/* <p className="subTitle">添加学校</p> */}

  <Form
    ref={form}
    layout="horizontal"
    mode="card"
    onFinish={submit}
    footer={
      <div>

        <Button block type="submit" color="primary" size="large">
          提交
        </Button>
      </div>
    }
  >
    <Form.Header>添加学校</Form.Header>
    {/* <Form.Item
    label="单位名称"
    disabled={type === "edit"}
    name="unitName"
    required={false}
  >
    <Input placeholder="请输入" readOnly={type === "admin"} />
  </Form.Item> */}
    <Form.Item
      label="学校名称"
      disabled={type === "edit"}
      name="school"
      required={false}
    >
      <Input placeholder="请输入" readOnly={type === "admin"} />
    </Form.Item>
   
 

    {/* <Form.Item
    label="生日"
    onClick={(e, datePickerRef) => {
      if (type === "admin") {
        return;
      }
      datePickerRef.current?.open();
    }}
    name="birthday"
    trigger="onConfirm"
    required={false}
  >
    <DatePicker
      title="选择生日"
      min={new Date(new Date().getFullYear() - 50)}
      max={new Date()}
    >
      {(date) => {
        if (date) {
          return dayjs(date).format("YYYY-MM-DD");
        } else {
          return "请选择生日";
        }
      }}
    </DatePicker>
  </Form.Item> */}


 
    <Form.Item
      label="所在城市"
      name="address"
      required={false}
      trigger="onConfirm"
      onClick={(e, cascaderRef) => {
        if (type === "admin") {
          return;
        }
        cascaderRef.current?.open();
      }}
    >
      <Cascader options={cityData}>
        {(e) => {
          if (e && e.length) {
            return e.map((v) => v.label).join("-");
          } else {
            return "请选择所在城市";
          }
        }}
      </Cascader>
    </Form.Item>
    <Form.Header></Form.Header>
  </Form>
  
  <Form
    ref={form}
    layout="horizontal"
    mode="card"
    onFinish={submit}
    footer={
      <div>

      
      </div>
    }
  >
        <Form.Header>添加前查询学校</Form.Header>

      <Form.Item
      trigger="onConfirm"
      label="所在学校"
      name="school"
      required={false}
      onClick={(e, SearchSchoolRef) => {
        SearchSchoolRef.current?.open();
      }}
    >
      <SearchSchool />
    </Form.Item>
 
  </Form></div>
)
};
export default ExamDetail;
