import "./header.css";
import {
  Dropdown,
  CascaderView
} from "antd-mobile";
import { useCityData, useLoginStore } from "../../store";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import Request from "../../unit/request";
const createDateList = (max, min) => {
  const maxYear = max.getFullYear();
  const minYear = min.getFullYear();
  const createList = (length, unit, start = 1) => {
    const child = [];
    for (let i = 0; i < length; i++) {
      child.push({
        label: `${i + start}${unit}`,
        value: `${i + start <= 9 ? 0 : ""}${i + start}`,
      });
    }
    return child;
  };
  const list = createList(maxYear - minYear, "年", minYear);
  list.forEach((v) => {
    v.children = createList(12, "月");
    v.children.forEach((c) => {
      const maxDay = new Date(v.value, c.value, 0).getDate();
      c.children = createList(maxDay, "日");
    });
  });
  return list;
};
const Header = (props) => {
	const { loginInfo } = useLoginStore((state) => state);
  const [columnsList, setColumnsList] = useState([]);
  const getColumnsList = () => {
	if(!loginInfo.isLogin){
		return
	}
    Request({
      url: "/exam/rankList",
      method: "post",
    }).then((res) => {
      setColumnsList(res.data);
    });
  };
  const { cityData } = useCityData((state) => state);
  const dateData = createDateList(
    new Date("2099-01-01"),
    new Date("2022-01-01")
  );
  const [categoryLabel, setCategoryLabel] = useState("科目");
  const [type, setType] = useState("");
  const [rank, setRank] = useState("");
  const [area, setArea] = useState("");
  const [date, setDate] = useState("");
  const timer = useRef(null);
  const inputValue = useRef("");
  const updateValue = (v) => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    timer.current = setTimeout(() => {
      inputValue.current = v.target.value;
      dropOnChange(false);
    }, 500);
  };
  const { pathname } = useLocation();
  const navigator = useNavigate();
  const dropOnChange = (e) => {
    if (pathname !== "/main/exam") {
      navigator(`/main/exam?search=${inputValue.current}`);
      return;
    }
    if (!e) {
      props.onChange &&
        props.onChange({
          category: type,
          address: area.replace(/-/g, "|@|"),
          date,
          rank: rank,
          name: inputValue.current,
        });
    }
  };
  const inputValueRef = useRef(null);
  const { search } = useLocation();
  useEffect(() => {
    getColumnsList();
    const obj = qs.parse(search, { ignoreQueryPrefix: true });
    if (obj.search) {
      inputValue.current = obj.search;
      inputValueRef.current.value = obj.search;
      dropOnChange();
    }
  }, []);
  return (
    <div className={`headerInner ${props.drop ? "headerInnerDrop" : ""}`}>
      <div className="header">
        <div className="block">
          <div className="search">
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#icon-sousuo"></use>
            </svg>
            <input
              type="text"
              ref={inputValueRef}
              onInput={updateValue}
              placeholder="搜索测评名称"
            />
          </div>
        </div>
        {props.drop ? (
          <div className="drop">
            <Dropdown onChange={dropOnChange}>
              <Dropdown.Item key="sorter" title={categoryLabel}>
                <div className="areaDrop">
                  <CascaderView
                    options={columnsList}
                    onChange={(v, { items }) => {
                      if (v.length >= 1) {
                        setCategoryLabel(
                          items.map((item) => item.label).join("-")
                        );
                        setType(v[0]);
                        v[1] && setRank(v[1]);
                      } else {
                        setCategoryLabel("科目");
                      }
                    }}
                  />
                </div>
              </Dropdown.Item>
              <Dropdown.Item key="bizop" title={area || "区域"}>
                <div className="areaDrop">
                  <CascaderView
                    options={cityData}
                    onChange={(v) => {
                      if (v.length === 2) {
                        setArea(v.join("-"));
                      } else {
                        setArea("");
                      }
                    }}
                  />
                </div>
              </Dropdown.Item>
              <Dropdown.Item key="more1" title={date || "日期"}>
                <div className="areaDrop">
                  <CascaderView
                    options={dateData}
                    onChange={(v) => {
                      if (v.length >= 2) {
                        setDate(v.join("-"));
                      } else {
                        setDate("");
                      }
                    }}
                  />
                </div>
              </Dropdown.Item>
            </Dropdown>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Header;
