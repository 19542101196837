import { useEffect, useState, useRef, Fragment } from "react";
import { Image, Dropdown, Radio, Space, Popover, Tag, SearchBar,Button } from "antd-mobile";

import { useLocation } from "react-router-dom";
import copy from 'copy-to-clipboard';
import NoData from "../../components/noData/noData";
import getCloudImg from "../../unit/getCloudImg";
import getCloudDocumnet from "../../unit/getCloudDocumnet"
import Request from "../../unit/request";
import "./certManger.scss";
import upload from "../../unit/uploadImg";

import qs from "qs";
import { Toast } from "antd-mobile";

const CertManger = () => {
  const [examList, setExamList] = useState([]);


  const creatCert = (exam_id) => {
    Request({
      url: "/admin/exam/getCert",
      method: "post",
      data: {
        exam_id: exam_id,
      },
    }).then((res) => {
      let data = JSON.parse(res.data.url)
      if (data.msg) {
        Toast.show({
          content: res.data.msg
        });

      } else {
        Toast.show({
          content: data.errmsg
        });
      }

      getCertList()
    });
  }


  const downloadCrt = () => {
    Request({
      url: "/admin/exam/getPayCertList",
      method: "post",
      data: {
      },
    }).then((res) => {

      if (res.data.msg) {
        Toast.show({
          content: res.data.msg
        });
        return
      }

      copy(res.data.url, {
        message: '点击确定复制下载链接',
      });

      Toast.show({
        content: `${res.data.url}:已复制下载链接，请前往浏览器打开自动下载`,
      });
    });
  }




  const getCertUrl = (cert_url) => {
    console.log('111', cert_url)
    getCloudDocumnet([cert_url]).then((list) => {
      console.log("11111", list)
      copy(list, {
        message: '点击确定复制下载链接',
      });
      Toast.show({
        content: '已复制下载链接，请前往浏览器打开自动下载',
      });
    });
  }



  const getCertList = () => {
    Request({
      url: "/admin/exam/getCertList",
      method: "post",
      data: {
      },
    }).then((res) => {

      getCloudImg({
        key: "exam_field",
        list: res.data,
      }).then((list) => {

        // console.log('1111', list)
        setExamList(list);
      },
        (err) => {
          setExamList([]);
        });
    });
  }


  useEffect(() => {
    getCertList()
    console.log(111)
  }, []);



  // const uploadTmpRef = useRef(null);

  // function addStudentCert(e, exam_id) {
  //   upload({
  //     files: e.target.files,
  //     type: "scoreTmp",
  //     path: `scoreTmp/${exam_id}`,
  //   }).then((file) => {
  //     Request({
  //       url: "/admin/exam/insert",
  //       method: "post",
  //       data: {
  //         exam_id: exam_id,
  //         fildId: file[0].fileID,
  //       },
  //     }).then((res) => {
  //       Toast.show({
  //         icon: "success",
  //         content: `成功导入${res.data.updateNum}条数据`,
  //       });
  //     });
  //   });

  //   // Toast.show({
  //   //   content: '功能暂未上线',
  //   // });
  // }


  function creatButton(job_status, cert_url, exam_id) {
    // console.log(job_status, cert_url)
    const base =""
      // <div>
      //   <input
      //     type="file"
      //     ref={uploadTmpRef}
      //     className="uploadTmp"
      //     id="uploadTmp"
      //     onChange={(e) => addStudentCert(e, exam_id)}
      //   />
      //   <Button
      //     block
      //     className="examBtn" 
      //     shape="rounded"
      //     color="primary"
      //     onClick={() => {
      //       uploadTmpRef.current?.click();
      //     }}
      //   >
      //     补发证书
      //   </Button>
      //   {/* <div onClick={() => {
      //     uploadTmpRef.current?.click();
      //   }}>
      //     <p>补发证书</p>
      //   </div> */}
      // </div>


    if (job_status === null) {
      return (
        <div className="examBtnBox">
          <div>证书状态：暂未生成</div>
          <div className="examBtn" onClick={() => creatCert(exam_id)}>
            <p>生成证书</p>
          </div>
          {base}
        </div>

      )
    } else if (job_status === '0') {
      return (
        <div className="examBtnBox"> <div>证书状态：证书生成中</div>
          <div className="examBtn" style={{ background: "#ccc" }}>
            <p>证书生成中</p>
          </div>
        </div>
      )
    } else if (job_status === '1') {
      return (
        <div className="examBtnBox">  <div>证书状态：证书生成成功</div>
          <div className="examBtn" onClick={() => getCertUrl(cert_url)}>
            <p>下载证书</p>
          </div>
        </div>)
    } else if (job_status === '2') {
      return (
        <div className="examBtnBox"> <div>证书状态：证书生成失败请重新生成</div>
          <div className="examBtn" onClick={() => creatCert(exam_id)}>
            <p>重新生成</p>
          </div>
        </div>)
    }




  }







  function searchExam() {
    Toast.show({
      content: '功能暂未上线',
    });
  }


  return (
    <div className="examDetail">
      <p className="subTitle">证书支付数据下载</p>
      <div className="downloadCrt" onClick={() => downloadCrt()}>
        下载证书支付数据
      </div>


      <div className="adminApplyExam">
        <p className="subTitle">考试证书打包下载</p>
        <SearchBar
          placeholder="请输入搜索的考试名称"
          onChange={(v) => {
            searchExam(v);
          }}
        />
        <div className="examBox">
          <NoData list={examList} />
          {examList.map((v) => (
            // <div>111</div>
            <div className="examItem">
              <Image src={v.exam_field} alt="" lazy />
              <div className="examInner">
                <div className="examInfo">
                  <p className="examTitle">{v.exam_name}</p>
                  <Space>
                    <Tag color="primary" fill="outline">
                      {v.exam_category}
                      {v.exam_rank && `${v.exam_rank}级`}
                    </Tag>
                    <Tag color="#87d068" fill="outline">
                      参考学生{v.stu_Num || 0}人
                    </Tag>
                  </Space>

                  <p className="examAddress">
                    <svg className="icon" aria-hidden="true">
                      <use xlinkHref="#icon-weizhi1"></use>
                    </svg>
                    {v.exam_address && v.exam_address.split("|@|").join("-")}
                  </p>
                  <p className="examDate">
                    <svg className="icon" aria-hidden="true">
                      <use xlinkHref="#icon-shijian"></use>
                    </svg>
                    {v.exam_date.split(" ")[0]}
                  </p>


                </div>



                {creatButton(v.job_status, v.cert_url, v.exam_id)}


                <div>

                </div>

              </div>

            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default CertManger;
