import { Dialog, Swiper, Toast } from "antd-mobile";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/header/header";
import { useLoginStore } from "../../store";
import "./index.scss";
import Request from "../../unit/request";
import { useState } from "react";
import getCloudImg from "../../unit/getCloudImg";
import qs from "qs";
import { PhoneFill } from "antd-mobile-icons";
const showTel = () => {
	Dialog.alert({
	  header: (
		<PhoneFill
		  style={{
			fontSize: 44,
			color: 'var(--adm-color-primary)',
		  }}
		/>
	  ),
	  title: '联系方式',
	  content: (
		<>
		  <div className="telItem">　平台信息咨询：<a href="tel:010-58075068">010-58075068</a></div>
		  <div className="telItem">官方工作委员会：<a href="tel:010-5807506">010-58075068</a></div>
		  <div className="telItem">　　　　　　　　<a href="tel:18600753621">18600753621</a></div>
		</>
	  ),
	})
}
const quickLink = [
	{
		key: 0,
		text: "测评简介",
		icon: "icon-yunying",
		page: "/newsDetail?news_id=5"
	},
	{
		key: 1,
		text: "测评须知",
		icon: "icon-baoming",
		page: "/newsDetail?news_id=6",
	},
	{
		key: 2,
		text: "测评点查询",
		icon: "icon-pandian",
		page: "/adminStation?type=view",
	},
	{
		key: 3,
		text: "我要报名",
		icon: "icon-mubiao",
		page: "/main/exam",
	},{
		key: 4,
		text: "联系我们",
		icon: "icon-dianhua",
		fn: showTel
	},
];

const Index = () => {
	const [indexItem, setIndexItem] = useState();
	const [examList, setExamList] = useState([]);
	const [newsList, setNewsList] = useState([]);
	const getData = () => {
		Request({
			url: "/index/index",
			method: "get",
		}).then((res) => {
			getCloudImg({
				list: res.data.banner,
				key: "img",
			}).then((banners) => {
				let br = banners.map((item, index) => (
					<Swiper.Item key={index}>
						<div className="indexItem" onClick={() => toDetail(item)}>
							<img className="swiperImg" src={item.img} alt="" />
							<div className="swiperShadow">
								<div className="swiperTitle">
									{item.title}
								</div>
								<div className="swiperTime">{item.date || ''}</div>
							</div>
						</div>
					</Swiper.Item>
				));
				setIndexItem(br);
				//setBannerList(banners);
			});
			getCloudImg({
				list: res.data.exam.slice(0, 2),
				key: "exam_field",
			}).then((exam) => {
				setExamList(exam);
			});
			setNewsList(res.data.news);
		});
	};
	const navigate = useNavigate();
	const { search } = useLocation();

	const { ToLogin, loginInfo, GetUserInfo } = useLoginStore((state) => state);
	const toPages = (page) => {
		navigate(page);
	};
	const toDetail = (v) => {
		navigate(`/newsDetail?news_id=${v.id}`);
	};
	const toExamList = () => {
		navigate(`/main/exam`);
	};
	const toExamDetail = (v) => {
		if (loginInfo.isLogin) {
			navigate(`/examDetail?exam_id=${v.exam_id}`);
		} else {
			ToLogin(`/#/examDetail?exam_id=${v.exam_id}`);
		}
	};
	useEffect(() => {
		GetUserInfo().then((user) => {
			let role =
				qs.parse(search, { ignoreQueryPrefix: true })?.role ||
				sessionStorage.webRole;
			if (role) {
				if (!!user.userInfo.user_level_id === false) {
					sessionStorage.webRole = role;
				} else {
					let roleLabel = "";
					switch (user.userInfo.user_level_id) {
						case 1:
							roleLabel = "学生";
							break;
						case 2:
							roleLabel = "测评员";
							break;
						case 3:
							roleLabel = "测评中心管理员";
							break;
						case 4:
							roleLabel = "承办机构管理员";
							break;
						case 5:
							roleLabel = "组委会";
							break;
						default:
							break;
					}
					Toast.show({
						icon: "success",
						content: `您的账号已注册角色为${roleLabel}，即将跳转至${roleLabel}功能页`,
					});
					sessionStorage.removeItem("webRole");
					setTimeout(() => {
						navigate("/main/index");
						window.location.reload();
					}, 1500);
				}
			}
		});
		getData();
	}, []);
	return (
		<div className="index">
			<Header />
			<div className="indexSwiperBox">
				<Swiper
					style={{
						"--track-padding": " 0 0 22px",
					}}
					defaultIndex={0}
				>
					{indexItem}
				</Swiper>
			</div>
			<div className="quickLink">
				{
					quickLink.map(item => (
						<div className="quickLinkItem" key={item.key} onClick={() => item.page?toPages(item.page):item.fn?item.fn():''}>
							<svg className="icon quickLinkIcon" aria-hidden="true">
								<use xlinkHref={`#${item.icon}`}></use>
							</svg>
							<p className="quickLinkText">{item.text}</p>
						</div>
					))
				}
			</div>
			<div className="newsExam content" style={{ marginTop: "30px" }}>
				<div className="title">
					最新测评
					<span className="titleMore" onClick={toExamList}>
						查看更多
					</span>
				</div>
				<div className="newsExamInner">
					{examList.length <= 0 && <div className="noDataExam">暂无测评</div>}
					{examList.map((v) => (
						<div className="newsExamInnerItem" onClick={() => toExamDetail(v)}>
							<img src={v.exam_field} alt="" />
							<p className="stationName">{v.exam_name}</p>
							<p className="stationAddress">{v.exam_detailAddress}</p>
						</div>
					))}
				</div>
			</div>
			<div className="newsInfo content">
				<div className="title">
					最新动态
					{/*<span className="titleMore" onClick={toNewsList}>
            查看更多
          </span>*/}
				</div>
				<div className="newsInfoInner">
					{newsList.length <= 0 && <div className="noDataNew">暂无动态</div>}
					{newsList.map((v, i) => (
						<div
							className="newsInfoInnerItem"
							key={i}
							onClick={() => toDetail(v)}
							rel="noreferrer"
						>
							<p className="newsInfoTitle">{v.title}</p>
							<div className="newsSubInfo">
								<p className="newsInfoSource">{v.href}</p>
								<p className="newsInfoDate">{v.date}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Index;
