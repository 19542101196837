import getCloudImg from "../../../unit/getCloudImg";
import Request from "../../../unit/request";

const getApplyDetail = (obj) => {
	console.log(obj, "obj")
	return new Promise((r, j) => {
		Request({
			url: "/admin/exam/enrollDetail",
			method: "post",
			data: {
				type: obj.type,
				exam_id: obj.exam_id,
			},
		}).then((res) => {
			const arr = res.data?.enrollList || []
			arr.forEach(v => {
				try {
					let scoreArr = []
					let score = JSON.parse(v.scoreList)
					for (let i in score) {
						scoreArr.push({
							name: i,
							score: score[i]
						})
					}
					v.scoreList = scoreArr
				} catch (error) {
					v.scoreList = []
				}
			});
			const arrList = arr.filter(obj.filter);
			getCloudImg({
				key: "photo",
				alias: "img",
				list: arrList,
			}).then((list) => {
				r(list)
			});
		});
	})
};

export default getApplyDetail