export function formatDate(time,isDatetime ) {
    var now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var date = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();
    if (isDatetime == 'datetime') {
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`
    } else {
        return `${year}${month}${date}${hour}`
    }
}

export function formatDate1(time,isDatetime ) {
    var now = new Date(time);
    let date=time;
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2); // 添加前导零并截取最后两位
    var day = ('0' + date.getDate()).slice(-2); // 添加前导零并截取最后两位
    var hour = ('0' + date.getHours()).slice(-2); // 添加前导零并截取最后两位
    var minute = ('0' + date.getMinutes()).slice(-2); // 添加前导零并截取最后两位
    var second = ('0' + date.getSeconds()).slice(-2); // 添加前导零并截取最后两位
    var milliseconds = ('00' + date.getMilliseconds()).slice(-3); // 添加前导零并截取最后三位

    if (isDatetime == 'datetime') {
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    } else {
        return `${year}${month}${date}${hour}`
    }
}
