import { useEffect, useState } from "react";
import { examinee, examiner } from "./protocolData";
import { useLocation, useNavigate } from "react-router-dom";
import "./protocol.css";
const Protocol = () => {
  const location = useLocation();
  const [hyper, setHyper] = useState();
  const [agreeTime, setAgreeTimeNum] = useState(30);
  let timer = null;
  const setAgreeTime = () => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    let second = agreeTime;
    timer = setInterval(() => {
      second--;
      if (second < 0) {
        clearInterval(timer);
        timer = null;
        return;
      }
      setAgreeTimeNum(second);
    }, 1000);
  };
  const navigator = useNavigate();
  const agreeSubmit = () => {
    if (agreeTime > 0) {
      return;
    }
    sessionStorage.isAgree = true;
    navigator(-1);
  };
  useEffect(() => {
    const { search } = location;
    setHyper(search.indexOf("examiner") > -1 ? examiner : examinee);
    setAgreeTime();
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);
  return (
    <div className="protocol">
      <div dangerouslySetInnerHTML={{ __html: hyper }}></div>
      <div
        onClick={agreeSubmit}
        className={`agreeBtn ${agreeTime <= 0 ? "" : "agreeTiming"}`}
      >
        {agreeTime ? `(${agreeTime}s)` : ""} 我已阅读并同意协议
      </div>
    </div>
  );
};

export default Protocol;
