import { Mask, SpinLoading } from "antd-mobile";
import { outside } from "../../unit/request";
import { useLoading } from "../../store";
import "./loadingMask.css";

const LoadingMask = () => {
  const { show, open, hide } = useLoading((state) => state);
  outside(open, hide);
  return (
    <Mask visible={show} color="white">
      <div className="loading">
        <SpinLoading color="primary" />
      </div>
    </Mask>
  );
};

export default LoadingMask;
