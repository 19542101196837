import tcb from "@cloudbase/js-sdk";
const app = tcb.init({
	env: "bjsports-3gjwsnfg45ed42fd",
	appid: 'wx8214df45cf195e9e', // 运行的公众号APPID
	resourceAppid: 'wx8214df45cf195e9e' // 资源方账号APPID
});


const getCloudDocumnet = (data) => {
	console.log(data)
	// cloud://prod-1gus8gkm3338bb20.7072-prod-1gus8gkm3338bb20-1313984361/admin/tmp/exam/cert/100151_1705872738468_output.zip
	// https://7072-prod-1gus8gkm3338bb20-1313984361.tcb.qcloud.la/admin/tmp/exam/cert/100151_1705872738468_output.zip

	return new Promise((r, j) => {
		const fileUrl = data[0].replace('cloud://prod-1gus8gkm3338bb20.7072-prod-1gus8gkm3338bb20-1313984361', 'https://7072-prod-1gus8gkm3338bb20-1313984361.tcb.qcloud.la')
		r(fileUrl)


	})
}






export default getCloudDocumnet