import {
  Form,
  Input,
  TextArea,
  Button,
  Picker,
  DatePicker,
  Cascader,
  Toast,
  CascadePicker,
  Stepper,
} from "antd-mobile";
import { useNavigate } from "react-router-dom";
import verify from "../../../unit/verify";
import { useCityData } from "../../../store";
import "./addExam.css";
import Request from "../../../unit/request";
import UploaderImg from "../../../components/uploadImg/uploadImg";
import { useLoginStore } from "../../../store";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useRef } from "react";
const timeColumns = [
  {
    label: "上午",
    value: "上午",
    children: [
      {
        label: "06:00",
        value: "06:00",
      },
      {
        label: "07:00",
        value: "07:00",
      },
      {
        label: "08:00",
        value: "08:00",
      },
      {
        label: "09:00",
        value: "09:00",
      },
      {
        label: "10:00",
        value: "10:00",
      },
      {
        label: "11:00",
        value: "11:00",
      },
      {
        label: "12:00",
        value: "12:00",
      },
    ],
  },
  {
    label: "下午",
    value: "下午",
    children: [
      {
        label: "13:00",
        value: "13:00",
      },
      {
        label: "14:00",
        value: "14:00",
      },
      {
        label: "15:00",
        value: "15:00",
      },
      {
        label: "16:00",
        value: "16:00",
      },
      {
        label: "17:00",
        value: "17:00",
      },
      {
        label: "18:00",
        value: "18:00",
      },
      {
        label: "19:00",
        value: "19:00",
      },
      {
        label: "20:00",
        value: "20:00",
      },
      {
        label: "21:00",
        value: "21:00",
      },
      {
        label: "22:00",
        value: "22:00",
      },
    ],
  },
];
const AddExam = () => {
  const { loginInfo, GetUserInfo } = useLoginStore((state) => state);
  const { cityData } = useCityData((state) => state);
  const navigate = useNavigate();
  const [columnsList, setColumnsList] = useState([]);
  const getColumnsList = () => {
    Request({
      url: "/exam/rankList",
      method: "post",
      data: {
        ins_id: loginInfo.userInfo.institution_id,
      },
    }).then((res) => {
      setColumnsList(res.data);
    });
  };
  const submit = (e) => {
    const params = e;
    params.exam_peo_stu = params.exam_peo_stu * 1;
    params.exam_institution = params.exam_institution
      ? params.exam_institution[0]
      : "";
    params.exam_peo_examine = params.exam_peo_examine * 1;
    params.exam_address = params.exam_address
      ? params.exam_address.join("|@|")
      : "";
    params.exam_rank = params.exam_category ? params.exam_category[1] : "";
    params.exam_category = params.exam_category ? params.exam_category[0] : "";
    params.exam_date = params.exam_date
      ? dayjs(params.exam_date).format("YYYY-MM-DD")
      : "";
    params.exam_field = params.exam_field ? params.exam_field[0]?.serverId : "";
    params.img = params.img ? params.img[0]?.serverId : "";
    params.exam_startTime = params.exam_startTime
      ? params.exam_startTime.join(" ")
      : "";
    params.exam_endTime = params.exam_endTime
      ? params.exam_endTime.join(" ")
      : "";
    if (params.exam_peo_stu <= 0) {
      Toast.show("测评名额必须大于0");
      return;
    }
    if (params.exam_peo_examine <= 0) {
      Toast.show("测评员名额必须大于0");
      return;
    }
    let rule = [
      { key: "exam_institution", msg: "请选择测评中心" },
      { key: "exam_address", msg: "测评中心所在城市不能为空" },
      { key: "exam_detailAddress", msg: "测评中心考场地址不能为空" },
      { key: "exam_category", msg: "请选择测评类别" },
      { key: "exam_rank", msg: "请选择测评等级" },
      { key: "exam_peo_stu", msg: "请输入测评名额" },
      { key: "exam_peo_examine", msg: "请输入测评员名额" },
      { key: "exam_date", msg: "请选择测评日期" },
      { key: "exam_startTime", msg: "请选择测评开始时间" },
      { key: "exam_endTime", msg: "请选择测评结束是时间" },
      { key: "exam_field", msg: "请上传考场图片" },
      { key: "exam_brife", msg: "请输入测评简介" },
      { key: "img", msg: "请上传测评介绍封面图" },
      { key: "fee", msg: "请输入报名费用" },
    ];
    if (params.exam_category === "足球" || params.exam_category === "篮球") {
      params.match_address = params.match_address
        ? params.match_address.join("|@|")
        : "";
      params.match_field = params.match_field
        ? params.match_field[0]?.serverId
        : "";
      params.match_date = params.match_date
        ? dayjs(params.match_date).format("YYYY-MM-DD")
        : "";
      params.match_startTime = params.match_startTime
        ? params.match_startTime.join(" ")
        : "";
      params.match_endTime = params.match_endTime
        ? params.match_endTime.join(" ")
        : "";
      rule = [
        ...rule,
        { key: "match_address", msg: "赛事所在城市不能为空" },
        { key: "match_detailAddress", msg: "赛场详细地址不能为空" },
        { key: "match_date", msg: "请选择赛事日期" },
        { key: "match_startTime", msg: "请选择赛事开始时间" },
        { key: "match_endTime", msg: "请选择赛事结束时间" },
        { key: "match_field", msg: "请上传赛场图片" },
      ];
    }

    const _verify = verify(params, rule);
    if (_verify) {
      Toast.show({
        content: _verify.msg,
      });
      return;
    }
    if (
      params.exam_startTime.split(" ")[1] >= params.exam_endTime.split(" ")[1]
    ) {
      Toast.show({
        content: "开始时间必须小于结束时间",
      });
      return;
    }
    params.publish_ins_id = loginInfo.userInfo.institution_id;
    Request({
      url: "/exam/add",
      method: "post",
      data: {
        form: params,
      },
    }).then((res) => {
      Toast.show({
        icon: "success",
        content: "提交成功",
      });
      setTimeout(() => {
        navigate(-1);
      }, 1500);
    });
  };
  const [orgList, setOrgList] = useState([]);
  const getOrgList = () => {
    Request({
      url: "/institution/list",
      method: "post",
      data: {
        ins_id: loginInfo.userInfo.institution_id,
      },
    }).then((res) => {
      let arr = res.data || [];
      arr.forEach((v) => {
        v.label = v.name;
        v.value = v.institution_id;
      });
      setOrgList([arr]);
    });
  };
  const formValuesChange = (e) => {
    if (!e.exam_institution) {
      return;
    }
    Request({
      url: "/institution/detail",
      method: "post",
      data: {
        institution_id: e.exam_institution[0],
      },
    }).then((res) => {
      const fields = {};
      if (res.data.address) {
        fields.exam_address = res.data.address.split("|@|");
        fields.match_address = res.data.address.split("|@|");
      }
      if (res.data.detialAdress) {
        fields.exam_detailAddress = res.data.detialAdress;
        fields.match_detailAddress = res.data.detialAdress;
      }
      form.current.setFieldsValue(fields);
    });
  };
  const form = useRef(null);
  useEffect(() => {
    GetUserInfo().then((res) => {
      getOrgList();
      getColumnsList();
    });
  }, []);
  return (
    <div className="report">
      <Form
        ref={form}
        layout="horizontal"
        mode="card"
        onValuesChange={formValuesChange}
        onFinish={submit}
        footer={
          <div>
            <Button block type="submit" color="primary" size="large">
              发布测评
            </Button>
          </div>
        }
      >
        <Form.Header>测评信息</Form.Header>
        <Form.Item
          label="测评中心"
          required={false}
          trigger="onConfirm"
          onClick={(e, pickerRef) => {
            pickerRef.current?.open();
          }}
          name="exam_institution"
        >
          <Picker columns={orgList}>
            {(e) => {
              return <div>{e[0]?.label || "请选择测评中心"}</div>;
            }}
          </Picker>
        </Form.Item>
        <Form.Item
          label="所在城市"
          required={false}
          trigger="onConfirm"
          name="exam_address"
          onClick={(e, cascaderRef) => {
            cascaderRef.current?.open();
          }}
        >
          <Cascader options={cityData}>
            {(e) => {
              return (
                <div>
                  {e.length
                    ? e.map((v) => v.label).join("-")
                    : "请选择所在城市"}
                </div>
              );
            }}
          </Cascader>
        </Form.Item>
        <Form.Item label="考场地址" name="exam_detailAddress" required={false}>
          <Input placeholder="请输入考场地址" />
        </Form.Item>
        <Form.Item
          label="测评科目"
          required={false}
          trigger="onConfirm"
          onClick={(e, cascadePickerRef) => {
            cascadePickerRef.current?.open();
          }}
          name="exam_category"
        >
          <CascadePicker title="测评科目" options={columnsList}>
            {(items = []) => {
              return (
                <div>
                  {items.length > 1
                    ? `${items[0]?.label}-${items[1]?.label}`
                    : "请选择测评科目"}
                </div>
              );
            }}
          </CascadePicker>
        </Form.Item>
        <Form.Item
          label="测评名额"
          initialValue={20}
          name="exam_peo_stu"
          required={false}
        >
          <Input type="number" placeholder="请输入测评名额数量" min={0} />
        </Form.Item>
        <Form.Item
          label="测评员名额"
          initialValue={3}
          name="exam_peo_examine"
          required={false}
        >
          <Input type="number" placeholder="请输入测评员名额数量" min={0} />
        </Form.Item>

        <Form.Item
          label="测评日期"
          trigger="onConfirm"
          name="exam_date"
          onClick={(e, datePickerRef) => {
            datePickerRef.current?.open();
          }}
          required={false}
        >
          <DatePicker title="选择测评日期" min={function () {
            let minDate = new Date();
            minDate.setFullYear(minDate.getFullYear() - 1);
            console.log("111", minDate);
            return minDate; // 确保返回一个Date对象
          }()}>
            {(date) => {
              if (date) {
                return dayjs(date).format("YYYY-MM-DD");
              } else {
                return "选择测评日期";
              }
            }}
          </DatePicker>
        </Form.Item>
        <Form.Item
          label="开始时间"
          onClick={(e, cascadePickerRef) => {
            cascadePickerRef.current?.open();
          }}
          name="exam_startTime"
          trigger="onConfirm"
          required={false}
        >
          <CascadePicker title="测评开始时间" options={timeColumns}>
            {(date) => {
              if (date.length) {
                return date.map((v) => v.label).join(" ");
              } else {
                return "请选择测评开始时间";
              }
            }}
          </CascadePicker>
        </Form.Item>
        <Form.Item
          label="结束时间"
          onClick={(e, cascadePickerRef) => {
            cascadePickerRef.current?.open();
          }}
          trigger="onConfirm"
          name="exam_endTime"
          required={false}
        >
          <CascadePicker title="测评结束时间" options={timeColumns}>
            {(date) => {
              if (date.length) {
                return date.map((v) => v.label).join(" ");
              } else {
                return "请选择测评结束时间";
              }
            }}
          </CascadePicker>
        </Form.Item>
        <Form.Item label="测评介绍" name="exam_brife" required={false}>
          <TextArea placeholder="请输入测评介绍" />
        </Form.Item>
        <Form.Item label="介绍封面" name="img" required={false}>
          <UploaderImg path="addExam" />
        </Form.Item>
        <Form.Item label="考场图片" required={false} name="exam_field">
          <UploaderImg path="addExam" />
        </Form.Item>
        <Form.Item
          label="测评费用"
          name="fee"
          required={false}
          childElementPosition="right"
        >
          <Stepper digits={2} defaultValue={0.01} min={0.01} />
        </Form.Item>
        <Form.Header></Form.Header>
        <Form.Subscribe to={["exam_category"]}>
          {(form) =>
            form.exam_category &&
            (form.exam_category[0] === "足球" ||
              form.exam_category[0] === "篮球") && (
              <>
                <Form.Item
                  label="赛事城市"
                  required={false}
                  name="match_address"
                  trigger="onConfirm"
                  onClick={(e, cascaderRef) => {
                    cascaderRef.current?.open();
                  }}
                >
                  <Cascader options={cityData}>
                    {(e) => {
                      return (
                        <div>
                          {e.length
                            ? e.map((v) => v.label).join("-")
                            : "请选择城市"}
                        </div>
                      );
                    }}
                  </Cascader>
                </Form.Item>
                <Form.Item
                  label="赛场地址"
                  name="match_detailAddress"
                  required={false}
                >
                  <Input placeholder="请输入赛场详细地址" />
                </Form.Item>
                <Form.Item
                  label="赛事日期"
                  onClick={(e, datePickerRef) => {
                    datePickerRef.current?.open();
                  }}
                  name="match_date"
                  trigger="onConfirm"
                  required={false}
                >
                  <DatePicker title="请选择赛事日期" min={new Date()}>
                    {(date) => {
                      if (date) {
                        return dayjs(date).format("YYYY-MM-DD");
                      } else {
                        return "选择赛事日期";
                      }
                    }}
                  </DatePicker>
                </Form.Item>
                <Form.Item
                  label="开始时间"
                  onClick={(e, cascadePickerRef) => {
                    cascadePickerRef.current?.open();
                  }}
                  name="match_startTime"
                  trigger="onConfirm"
                  required={false}
                >
                  <CascadePicker title="赛事开始时间" options={timeColumns}>
                    {(date) => {
                      if (date.length) {
                        return date.map((v) => v.label).join(" ");
                      } else {
                        return "请选择赛事开始时间";
                      }
                    }}
                  </CascadePicker>
                </Form.Item>
                <Form.Item
                  label="结束时间"
                  onClick={(e, cascadePickerRef) => {
                    cascadePickerRef.current?.open();
                  }}
                  trigger="onConfirm"
                  name="match_endTime"
                  required={false}
                >
                  <CascadePicker title="赛事结束时间" options={timeColumns}>
                    {(date) => {
                      if (date.length) {
                        return date.map((v) => v.label).join("-");
                      } else {
                        return "请选择赛事结束时间";
                      }
                    }}
                  </CascadePicker>
                </Form.Item>
                <Form.Item label="赛事介绍" name="match_brife" required={false}>
                  <TextArea placeholder="请输入赛事介绍" />
                </Form.Item>
                <Form.Item label="赛场图片" name="match_field" required={false}>
                  <UploaderImg path="addExam" />
                </Form.Item>
              </>
            )
          }
        </Form.Subscribe>
      </Form>
    </div >
  );
};
export default AddExam;
