import Request from "./request";
const getCityData = () => new Promise((r) => {
	try {
		let cityData = JSON.parse(localStorage.cityData)
		if (cityData) {
			r(cityData)
			return
		}
	} catch (error) {

	}

	Request({
		url: '/cityData.json',
		urlType: 'local',
		method: 'get'
	}).then(res => {
		localStorage.cityData = JSON.stringify(res)
		r(res)
	})
})
export default getCityData