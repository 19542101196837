import Request from "../../../unit/request";
import { Tag } from "antd-mobile";
import { useEffect } from "react";
import { useState } from "react";
import getCloudImg from "../../../unit/getCloudImg";
import "./myInvigilating.css";
import { useNavigate } from "react-router-dom";
import NoData from "../../../components/noData/noData";
const MyInvigilating = () => {
  const navigator = useNavigate();
  const rootBtn = (v) => {
    const { verify } = v;
    let el = null;
    switch (verify) {
      case 0:
        el = (
          <Tag color="primary" fill="outline">
            审核中
          </Tag>
        );
        break;
      case 1:
        el = (
          <Tag
            color="#87d068"
            style={{ textAlign: "center", lineHeight: "16px" }}
            fill="outline"
          >
            <p>报名成功</p>
            <p>请准时到场参加监考</p>
          </Tag>
        );
        break;
      case 2:
        el = (
          <Tag color="#ff6430" fill="outline">
            审核未通过
          </Tag>
        );
        break;
      default:
        break;
    }
    return el;
  };
  const toDetail = (e) => {
    navigator(`/examDetail?exam_id=${e.exam_id}&type=view`);
  };
  const createExamList = (v) => {
    return (
      <div className="examItem">
        <img src={v.img} alt="" onClick={() => toDetail(v)} />
        <div className="examInner">
          <div className="examInfo" onClick={() => toDetail(v)}>
            <p className="examTitle">{v.exam_name}</p>
            <Tag color="primary" fill="outline">
              {v.exam_category}
              {v.exam_rank && `${v.exam_rank}级`}
            </Tag>
            <p className="examAddress">
              <svg className="icon" aria-hidden="true">
                <use xlinkHref="#icon-weizhi1"></use>
              </svg>
              {v.exam_address && v.exam_address.split("|@|").join("")}
            </p>
            <p className="examDate">
              <svg className="icon" aria-hidden="true">
                <use xlinkHref="#icon-shijian"></use>
              </svg>
              {v.exam_date && v.exam_date.split(" ")[0]}
            </p>
          </div>
          {rootBtn(v)}
        </div>
      </div>
    );
  };
  const [examList, setExamList] = useState([]);
  const getList = () => {
    Request({
      url: "/examine/enrollList",
      method: "post",
    }).then((res) => {
      getCloudImg({
        key: "img",
        list: res.data,
      }).then((list) => {
        setExamList(list);
      });
    });
  };
  useEffect(() => {
    getList();
  }, []);
  return (
    <div className="examBox">
      <NoData list={examList} />
      {examList.map((v) => createExamList(v))}
    </div>
  );
};

export default MyInvigilating;
