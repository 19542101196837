import {
  Form,
  Input,
  Button,
  Checkbox,
  Cascader,
  Toast,
  Picker,
  Tag,
  Space,
  Dialog,
  CascadePicker,
} from "antd-mobile";
import { AddCircleOutline, ExclamationCircleFill } from "antd-mobile-icons";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import verify from "../../unit/verify";
import "./report.css";
import Request from "../../unit/request";
import UploaderImg from "../../components/uploadImg/uploadImg";
import { useCityData } from "../../store";
import SendCode from "../../components/sendCode/sendCode";
import qs from "qs";
import getCloudImg from "../../unit/getCloudImg";
import { useLoginStore } from "../../store";
const orgType = [
  [
    { label: "承办机构", value: 4 },
    { label: "测评中心", value: 3 },
  ],
];
let parentRule = [
  { key: "name", msg: "请输入单位名称" },
  { key: "address", msg: "请选择所在城市" },
  { key: "detialAdress", msg: "请输入详细地址" },
  { key: "email", msg: "请输入电子邮箱" },
  { key: "landline", msg: "请输入固定电话" },
  { key: "venPhoto1", msg: "至少上传一张机构图片" },
  {
    key: "exam_options",
    child: [
      {
        key: "address",
        msg: "请选择承办科目${i}-区域",
      },
      {
        key: "category",
        msg: "请选择承办科目${i}-科目",
      },
      {
        key: "rank",
        msg: "请选择承办科目${i}-等级",
      },
    ],
    msg: "至少添加一项承办科目",
  },
  { key: "phoneNumber", msg: "请输入手机号" },
  { key: "smsCode", msg: "请输入短信验证码" },
  { key: "isProtocol", value: true, msg: "请同意报名协议" },
];
let childRule = [
  { key: "name", msg: "请输入站点名称" },
  { key: "address", msg: "请选择所在城市" },
  { key: "father_ins_id", msg: "请选择上级机构" },
  { key: "email", msg: "请输入电子邮箱" },
  { key: "landline", msg: "请输入固定电话" },
  { key: "venPhoto1", msg: "至少上传一张场地图片" },
  { key: "phoneNumber", msg: "请输入手机号" },
  { key: "smsCode", msg: "请输入短信验证码" },
  { key: "isProtocol", value: true, msg: "请同意报名协议" },
];
const Report = () => {
  const { GetUserInfo } = useLoginStore((state) => state);
  const { cityData } = useCityData((state) => state);
  const [formData, setFormData] = useState({
    userLevel: "",
  });
  const [isProtocol, setIsProtocol] = useState(false);
  const [columnsList, setColumnsList] = useState([]);
  const getColumnsList = () => {
    Request({
      url: "/exam/rankList",
      method: "post",
    }).then((res) => {
      setColumnsList(res.data);
    });
  };
  const navigator = useNavigate();
  const submit = (e) => {
    let params = { ...e };
    params.isProtocol = isProtocol;
    if (params.userLevel && Array.isArray(params.userLevel)) {
      params.userLevel = params.userLevel[0];
    } else {
      Toast.show("请选择机构类型");
      return;
    }
    if (params.venPhoto && params.venPhoto.length) {
      params.venPhoto.forEach((v, i) => {
        params[`venPhoto${i + 1}`] = v.serverId;
      });
      delete params.venPhoto;
    }
    if (params.address && params.address.length) {
      params.address = params.address.join("|@|");
    }

    if (params.father_ins_id && Array.isArray(params.father_ins_id)) {
      params.father_ins_id = params.father_ins_id[0];
    }

    let exam_options = [];
    if (params.exam_options) {
      params.exam_options.forEach((v) => {
        exam_options.push({
          address: v.address ? v.address.join("|@|") : "",
          category: v.category ? v.category[0] : "",
          rank: v.category ? v.category[1] : "",
        });
      });
      params.exam_options = exam_options;
    }
    let sendCodeInfo = sendCode.current;

    params.phoneNumber = sendCodeInfo.phoneNum;
    params.smsCode = sendCodeInfo.smsCode;
    const _verify = verify(
      params,
      params.userLevel === 4 ? parentRule : childRule
    );
    if (_verify) {
      Toast.show({
        content: _verify.msg,
      });
      return;
    }
    const url = isReSubmit ? "/institution/modify" : "/user/auth";

    const getData = () => {
      if (isReSubmit) {
        if (isParent || isAdmin) {
          return {
            form: params,
            ins_id: insId.current,
          };
        } else {
          return {
            form: params,
          };
        }
      } else {
        return {
          userLevel: params.userLevel,
          form: params,
        };
      }
    };
    Request({
      url,
      method: "post",
      data: getData(),
    }).then((res) => {
      Toast.show({
        icon: "success",
        content: "提交成功",
      });
      setTimeout(() => {
        navigator(-1);
      }, 1500);
    });
  };
  const [orgList, setOrgList] = useState([]);
  const getOrgList = () => {
    Request({
      url: "/institution/list",
      method: "post",
      data: {
        ins_id: "",
      },
    }).then((res) => {
      let arr = res.data || [];
      arr.forEach((v) => {
        v.label = v.name;
        v.value = v.institution_id;
      });
      setOrgList([arr]);
    });
  };
  const toProtocol = () => {
    navigator(`/protocol?examiner`);
  };
  const form = useRef(null);
  const sendCode = useRef(null);
  const { search } = useLocation();
  const getOrgDetail = (id) => {
    Request({
      url: "/institution/detail",
      method: "post",
      data: {
        institution_id: id,
      },
    }).then((res) => {
      if (res.data.address) {
        res.data.address = res.data.address.split("|@|");
      }
      getCloudImg({
        key: "img",
        isFull: true,
        list: [
          { img: res.data.venPhoto1 },
          { img: res.data.venPhoto2 },
          { img: res.data.venPhoto3 },
        ],
      }).then((list) => {
        res.data.venPhoto = [list[0], list[1], list[2]];
        res.data.userLevel = [res.data.father_ins_id ? 3 : 4];
        res.data.father_ins_id = [res.data.father_ins_id];
        res.data.address = res.data.address || [];
        res.data.exam_options = res.data.exam_options || [];
        res.data.exam_options.forEach((v) => {
          v.address = v.address ? v.address.split("|@|") : [];
          v.category = v.category ? [v.category, v.rank] : [];
        });
        setFormData(res.data);
        form.current.setFieldsValue(res.data);
      });
    });
  };
  const examine = (verify) => {
    const url = `/admin/audit/insititution`;
    let data = {
      verify,
      user_id: formData.institution_user_id,
    };
    Request({
      url,
      method: "post",
      data,
    }).then((res) => {
      Toast.show({
        icon: "success",
        content: "审核已完成",
      });
      setTimeout(() => {
        navigator(-1);
      }, 1500);
    });
  };
  const [isAdmin, setIsAdmin] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isSelf, setIsSelf] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [isReSubmit, setIsReSubmit] = useState(false);
  const formFooter = () => {
    let footer = null;
    const selfFooter = (
      <div className="form-footer">
        <Button
          block
          onClick={async () => {
            const result = await Dialog.confirm({
              header: (
                <ExclamationCircleFill
                  style={{
                    fontSize: 64,
                    color: "var(--adm-color-warning)",
                  }}
                />
              ),
              title: "注意",
              content: isAdmin
                ? "修改信息需要重新进行审核，是否继续？"
                : "重新认证需要重新提交审核，是否继续？",
            });
            if (result) {
              setIsView(false);
              setIsSelf(false);
              setIsReSubmit(true);
            }
          }}
          color="primary"
          size="large"
        >
          {isAdmin ? "修改信息" : "重新认证"}
        </Button>
      </div>
    );
    if (isSelf || isParent) {
      footer = selfFooter;
    }
    if (isAdmin) {
      footer =
        formData.verify === 0 ? (
          <div className="adminBtn">
            <Space direction="vertical" block>
              <Button
                block
                shape="rounded"
                color="primary"
                onClick={() => examine(1)}
              >
                通过
              </Button>
              <Button
                block
                shape="rounded"
                color="danger"
                onClick={() => examine(2)}
              >
                不通过
              </Button>
            </Space>
          </div>
        ) : (
          selfFooter
        );
    }
    if (!isView) {
      footer = (
        <div>
          <Button block type="submit" color="primary" size="large">
            提交
          </Button>
          <Checkbox
            className="checkBoxSubmit"
            onChange={(val) => {
              setIsProtocol(!!val);
            }}
          >
            <div className="reportProtocol">
              已阅读同意
              <div className="examinerLink" onClick={toProtocol}>
                《测评机构报名协议》
              </div>
            </div>
          </Checkbox>
        </div>
      );
    }
    return footer;
  };
  const insId = useRef("");
  useEffect(() => {
    const { ins_id, type } = qs.parse(search, { ignoreQueryPrefix: true });
    if (ins_id) {
      insId.current = ins_id;
      setIsView(true);
      getOrgDetail(ins_id);
    }
    if (type === "admin") {
      setIsAdmin(true);
    }
    if (type === "self") {
      setIsSelf(true);
    }
    if (type === "parent") {
      setIsParent(true);
    }
    getOrgList();
    getColumnsList();
  }, []);

  return (
    <div className="report">
      <Form
        ref={form}
        layout="horizontal"
        mode="card"
        onFinish={submit}
        footer={formFooter()}
      >
        {(isSelf || isAdmin) && (
          <Form.Item label="审核状态" required={false}>
            {formData.verify === 0 ? (
              <Tag color="primary" fill="outline">
                审核中
              </Tag>
            ) : formData.verify === 1 ? (
              <Tag color="#87d068" fill="outline">
                审核通过
              </Tag>
            ) : (
              formData.verify === 2 && (
                <Tag color="#ff6430" fill="outline">
                  审核失败
                </Tag>
              )
            )}
          </Form.Item>
        )}
        <Form.Header></Form.Header>
        <Form.Item
          label="机构类型"
          name="userLevel"
          required={false}
          trigger="onConfirm"
          disabled={isParent && isReSubmit}
          onClick={(e, pickerRef) => {
            if (isView) {
              return;
            }
            pickerRef.current?.open();
          }}
        >
          <Picker columns={orgType}>
            {(e) => {
              return <div>{e[0]?.label || "请选机构类型"}</div>;
            }}
          </Picker>
        </Form.Item>
        <Form.Header>机构信息</Form.Header>
        <Form.Subscribe to={["userLevel"]}>
          {({ userLevel }) =>
            userLevel &&
            (userLevel[0] === 4 ? (
              <Form.Item label="单位名称" name="name" required={false}>
                <Input readOnly={isView} placeholder="请输入单位名称" />
              </Form.Item>
            ) : (
              <Form.Item label="站点名称" name="name" required={false}>
                <Input readOnly={isView} placeholder="请输入站点名称" />
              </Form.Item>
            ))
          }
        </Form.Subscribe>

        <Form.Item
          label="所在城市"
          required={false}
          trigger="onConfirm"
          name="address"
          onClick={(e, cascaderRef) => {
            if (isView) {
              return;
            }
            cascaderRef.current?.open();
          }}
        >
          <Cascader options={cityData}>
            {(e) => {
              return (
                <div>
                  {e.length ? e.map((v) => v.label).join("-") : "请选择城市"}
                </div>
              );
            }}
          </Cascader>
        </Form.Item>
        <Form.Item label="详细地址" name="detialAdress" required={false}>
          <Input readOnly={isView} placeholder="请输入机构详细地址" />
        </Form.Item>
        <Form.Subscribe to={["userLevel"]}>
          {(form) => {
            return (
              <>
                {form.userLevel && form.userLevel[0] === 3 && (
                  <Form.Item
                    label="上级机构"
                    name="father_ins_id"
                    required={false}
                    trigger="onConfirm"
                    onClick={(e, pickerRef) => {
                      if (isView) {
                        return;
                      }
                      pickerRef.current?.open();
                    }}
                  >
                    <Picker columns={orgList}>
                      {(e) => {
                        return <div>{e[0]?.label || "请选上级机构"}</div>;
                      }}
                    </Picker>
                  </Form.Item>
                )}
              </>
            );
          }}
        </Form.Subscribe>
        <Form.Item label="测评地址" name="examAddress" required={false}>
          <Input readOnly={isView} placeholder="请输入测评地址（选填）" />
        </Form.Item>
        <Form.Item label="电子邮箱" name="email" required={false}>
          <Input readOnly={isView} placeholder="请输入电子邮箱" />
        </Form.Item>
        <Form.Item label="固定电话" name="landline" required={false}>
          <Input readOnly={isView} placeholder="请输入固定电话" />
        </Form.Item>
        <Form.Subscribe to={["userLevel"]}>
          {({ userLevel }) =>
            userLevel &&
            (userLevel[0] === 4 ? (
              <Form.Item label="机构图片" name="venPhoto" required={false}>
                <UploaderImg
                  isView={isView}
                  max={3}
                  path="institutions"
                ></UploaderImg>
              </Form.Item>
            ) : (
              <Form.Item label="场地图片" name="venPhoto" required={false}>
                <UploaderImg
                  isView={isView}
                  max={3}
                  path="institutions"
                ></UploaderImg>
              </Form.Item>
            ))
          }
        </Form.Subscribe>
        <Form.Header></Form.Header>
        <Form.Subscribe to={["userLevel"]}>
          {(form) =>
            form.userLevel &&
            form.userLevel[0] === 4 && (
              <Form.Array
                name="exam_options"
                initialValue={[{}]}
                renderAdd={
                  !isView
                    ? () => (
                        <span>
                          <AddCircleOutline /> 添加
                        </span>
                      )
                    : null
                }
                renderHeader={({ index }, { remove }) => (
                  <>
                    <span>承办科目-{index + 1}</span>
                    {!isView && (
                      <span
                        href=" "
                        onClick={() => {
                          if (index === 0) {
                            Toast.show("至少添加一个承办科目");
                          } else {
                            remove(index);
                          }
                        }}
                        style={{ float: "right", color: "#4096fe" }}
                      >
                        删除
                      </span>
                    )}
                  </>
                )}
              >
                {(fields) => {
                  return fields.map(({ index }) => (
                    <>
                      <Form.Item
                        name={[index, "address"]}
                        label="区域"
                        required={false}
                        trigger="onConfirm"
                        onClick={(e, cascaderRef) => {
                          if (isView) {
                            return;
                          }
                          cascaderRef.current?.open();
                        }}
                      >
                        <Cascader options={cityData}>
                          {(e) => {
                            return (
                              <div>
                                {e.length
                                  ? e.map((v) => v.label).join("-")
                                  : "请选择区域"}
                              </div>
                            );
                          }}
                        </Cascader>
                      </Form.Item>

                      <Form.Item
                        name={[index, "category"]}
                        label="科目"
                        required={false}
                        trigger="onConfirm"
                        onClick={(e, cascadePickerRef) => {
                          if (isView) {
                            return;
                          }
                          cascadePickerRef.current?.open();
                        }}
                      >
                        <CascadePicker title="测评科目" options={columnsList}>
                          {(items = []) => {
                            return (
                              <div>
                                {items.length > 1
                                  ? `${items[0]?.label}${
                                      items[1]?.label
                                        ? "-" + items[1]?.label
                                        : ""
                                    }`
                                  : "请选择测评科目"}
                              </div>
                            );
                          }}
                        </CascadePicker>
                      </Form.Item>
                    </>
                  ));
                }}
              </Form.Array>
            )
          }
        </Form.Subscribe>

        <Form.Header></Form.Header>
        {!isView && (
          <>
            <SendCode ref={sendCode} />
          </>
        )}
      </Form>
    </div>
  );
};
export default Report;
