import {
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import Bottom from '../../components/bottom/bottom'
import Index from '../index/index'
import Exam from '../exam/exam'
import My from '../my/my'
import "./main.css";
const Main = () => {
  return (
    <div className="main">
        <Routes>
          <Route path="index" element={<Index />}></Route>
          <Route path="exam" element={<Exam />}></Route>
          <Route path="my" element={<My />}></Route>
          <Route path="*" element={<Navigate to="index" />}></Route>
        </Routes>
        <Bottom />
    </div>
  );
};

export default Main;
