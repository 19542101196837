import "./myExamList.scss";
import {
  Tabs,
  Dialog,
  Rate,
  TextArea,
  Toast,
  ImageViewer,
  Tag,
  Mask,
  Button,
} from "antd-mobile";
import { useLocation, useNavigate } from "react-router-dom";
import Request from "../../../unit/request";
import { InformationCircleOutline } from "antd-mobile-icons";
import { useEffect, useRef, useState } from "react";
import getCloudImg from "../../../unit/getCloudImg";
import qs from "qs";
import createCanvas from "../../../unit/crtCanvas";
import createTicketCanvas from "../../../unit/ticketCanvas";
import { useLoading } from "../../../store";
import examPay from "../../../unit/examPay";
import NoData from "../../../components/noData/noData";
const getNumberKey = (num) => {
  const nums = {
    1: "一",
    2: "二",
    3: "三",
    4: "四",
    5: "五",
    6: "六",
    7: "七",
  };
  return nums[num];
};
const MyExamList = () => {
  const certPay = (order_sn, status) => {
    if (status === 1) {
      Toast.show("您已完成支付");
      return;
    }
    Request({
      url: "/pay/prepay",
      method: "post",
      data: {
        order_sn,
      },
    }).then((res) => {
      let data = res.data;
      window.wx.chooseWXPay({
        timestamp: data.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
        nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
        package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: data.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
        paySign: data.paySign, // 支付签名
        success: function (res) {
          Toast.show({
            icon: "success",
            content: "支付成功,请及时前往领取地点领取",
          });
          searchScore(scoreItemData);
          // 支付成功后的回调函数
        },
        fail: function (err) {
          Toast.show({
            icon: "fail",
            content: "支付失败",
          });
        },
      });
    });
  };
  const { open, hide } = useLoading();
  const [list, setList] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const getStudent = () => {
    Request({
      url: "/user/myInnerUser",
      method: "get",
    }).then((res) => {
      const list = res.data.myInnerUser.filter((v) => v.userLevel === 1);
      getCloudImg({
        key: "photo",
        list,
      }).then((list) => {
        setList(list);
        setActiveKey(list[0].detail_id);
        getExamList(list[0].detail_id);
      });
    });
  };
  const [examList, setExamList] = useState([]);
  const getExamList = (student_id) => {
    Request({
      url: "/exam/enrollList",
      method: "post",
      data: {
        student_id,
      },
    }).then((res) => {
      if (res.data.length) {
        getCloudImg({
          key: "exam_field",
          list: res.data,
        }).then((exams) => {
          setExamList(exams);
        });
      } else {
        setExamList([]);
      }
    });
  };
  const tabsChange = (e) => {
    setActiveKey(e);
    getExamList(e);
  };
  const rate1 = useRef(0);
  const rate2 = useRef(0);
  let otherValue = "";
  const toEvaluate = (e) => {
    if (e.rated !== 0) {
      return;
    }
    Dialog.confirm({
      title: "请您评价本次测评",
      onConfirm: async () => {
        await Request({
          url: "/exam/questionnaire",
          method: "post",
          data: {
            exam_id: e.exam_id,
            student_id: activeKey,
            average_rate: (rate1.current + rate2.current) / 2,
            form: {
              processRate: rate1.current,
              examRate: rate2.current,
              otherValue: otherValue,
            },
          },
        });
        Toast.show({
          icon: "success",
          content: "感谢您的评价",
        });
        getExamList(activeKey);
      },
      onCancel: async () => {
        rate1.current = 0;
        rate2.current = 0;
        otherValue = "";
      },
      content: (
        <div className="evaluate">
          <div className="evaluateItem">
            <p className="label">测评流程</p>
            <Rate onChange={(e) => (rate1.current = e)} />
          </div>
          <div className="evaluateItem">
            <p className="label">测评系统</p>
            <Rate onChange={(e) => (rate2.current = e)} />
          </div>
          <div className="evaluateItem evaluateItem2">
            <p className="label">其他建议</p>
            <TextArea
              rows={3}
              placeholder="请输入内容"
              onChange={(val) => {
                otherValue = val;
              }}
            />
          </div>
        </div>
      ),
    });
  };
  const { search } = useLocation();
  const { type } = qs.parse(search, { ignoreQueryPrefix: true });
  const [demoImage, setDemoImage] = useState("");
  const [shwoDemoImage, setShowDemoImage] = useState(false);
  const [isShowBtn, setShowBtn] = useState(false);
  const showDemoImage = (url, showBtn) => {
    if (showBtn) {
      setShowBtn(true);
    }
    setDemoImage(url);
    setShowDemoImage(true);
    hide();
  };
  const [crtImgVisible, setCrtImgVisible] = useState(false);
  const [scoreItemData, setScoreItemData] = useState({
    scoreList: [],
  });
  const searchScore = (v) => {
    Request({
      url: "/exam/cert",
      method: "post",
      data: {
        exam_id: v.exam_id,
        student_id: activeKey,
      },
    }).then((res) => {
      try {
        let _score = JSON.parse(res.data.scoreList);
        if (_score) {
          let arr = [];
          for (let i in _score) {
            arr.push({
              key: i,
              value: _score[i],
            });
          }
          _score = arr;
        }
        res.data.scoreList = _score;
      } catch (error) {
        res.data.scoreList = [];
      }
      if (res.data?.scoreList && res.data.scoreList.length !== 0) {
        res.data.exam_date=v.exam_date
        setScoreItemData(res.data);
        setCrtImgVisible(true);
      } else {
        Toast.show({
          icon: <InformationCircleOutline />,
          content: "暂无成绩",
        });
      }
    });
  };
  const pay = (v) => {
    examPay(v.exam_id, activeKey).then((res) => {
      getExamList(activeKey);
    });
  };
  const createListBtn = (text, click = () => { }, v) => {
    return (
      <div className="studentExamBtn examBtnActive" onClick={() => click(v)}>
        {text}
      </div>
    );
  };
  const getListItemBtn = (v) => {
    if (v.pay_status === 0) {
      return createListBtn("待支付", pay, v);
    } else {
      let btn = null;
      if (v.passed === 0) {
        btn = createListBtn("下载准考证", createTicket, v);
      } else {
        if (v.rated === 0) {
          btn = createListBtn("待评价", toEvaluate, v);
        } else {
          btn = createListBtn("已评价");
        }
      }
      return btn;
    }
  };
  const createTicket = (v) => {
    Request({
      url: "/exam/adminTickit",
      method: "post",
      data: {
        exam_id: v.exam_id,
        student_id: activeKey,
      },
    }).then((res) => {
      getCloudImg({
        list: res.data,
        key: "photo",
      }).then((list) => {
        console.log(list, "list");
        const data = list[0];
        if (!data) {
          Toast.show("获取信息失败，请重试");
          return;
        }
        createTicketCanvas({
          name: data.name,
          sex: data.gender === 1 ? "男" : data.gender === 2 ? "女" : "-",
          code: data.id,
          category: data.exam_category,
          rank: `${getNumberKey(data.exam_rank)}级`,
          time: data.exam_date ? data.exam_date.split(" ")[0] : "",
          address: data.exam_address
            ? `${data.exam_address.replace("|@|", "")}${data.exam_detailAddress
            }`
            : "",
          head: data.photo,
          callback: showDemoImage,
        });
      });
    });
  };
  useEffect(() => {
    getStudent();
  }, []);
  return (
    <div className="myExamList publishExam" id="examList">
      <div className="topTabs">
        <Tabs onChange={tabsChange} activeKey={activeKey}>
          {list.map((v) => (
            <Tabs.Tab title={v.name} key={v.detail_id} />
          ))}
        </Tabs>
      </div>
      <div className="examBox">
        <NoData list={examList} text="暂无预约测评" />
        {examList.map((v) => (
          <div className="examItem">
            <div className="examImgBox">
              <img src={v.exam_field} alt="" />

              {v.passed == 1 ? (
                <div className="imgMask">
                  <div className="imgMaskInner imgMaskInnerSuccess">
                    <svg className="icon" aria-hidden="true">
                      <use xlinkHref="#icon-a-icon_wanchengtongguo"></use>
                    </svg>
                    <p>测评通过</p>
                  </div>
                </div>
              ) : v.passed == 2 ? (
                <div className="imgMask">
                  <div className="imgMaskInner imgMaskInnerError">
                    <svg className="icon" aria-hidden="true">
                      <use xlinkHref="#icon-icon_cuowu"></use>
                    </svg>
                    <p>测评未通过</p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="examInnerBox">
              <div className="examInner">
                <div className="examInfo">
                  <p className="examTitle">{v.exam_name}</p>
                  <Tag color="primary" fill="outline">
                    {v.exam_category}
                    {v.exam_rank && `${v.exam_rank}级`}
                  </Tag>
                  <p className="examAddress">
                    <svg className="icon" aria-hidden="true">
                      <use xlinkHref="#icon-weizhi1"></use>
                    </svg>
                    {v.exam_address && v.exam_address.split("|@|").join("")}
                  </p>
                  <p className="examDate">
                    <svg className="icon" aria-hidden="true">
                      <use xlinkHref="#icon-shijian"></use>
                    </svg>
                    {v.exam_date && v.exam_date.split(" ")[0]}
                  </p>
                </div>
                {type === "search" ? (
                  <div
                    className="studentExamBtn examBtnActive"
                    onClick={() => searchScore(v)}
                  >
                    查分数
                  </div>
                ) : (
                  getListItemBtn(v)
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <ImageViewer
        getContainer={document.body}
        image={demoImage}
        visible={shwoDemoImage}
        onClose={() => {
          setShowDemoImage(false);
        }}
        renderFooter={() =>
          isShowBtn && (
            <div className="renderFooter">
              <div className="renderFooterInfo">长按图片保存</div>
            </div>
          )
        }
      />
      <Mask visible={crtImgVisible}>
        <div className="examScoreList">
          <p className="examScoreTitle">测评成绩</p>
          {scoreItemData.scoreList.map((v, i) => {
            return (
              <p>
                {v.key}：{v.value}
              </p>
            );
          })}
          <p>测评状态：{scoreItemData.passed === 1 ? "通过" : "未通过"}</p>
          {scoreItemData.passed === 1 && (
            <>
              <p>证书编号：{scoreItemData.cert_sn}</p>
              <p>
                电子证书：
                <span
                  className="line"
                  onClick={() => {
                    const activeItem =
                      list.find((v) => v.detail_id == activeKey) || {};
                    open();
                    createCanvas(
                      showDemoImage,
                      scoreItemData.name || "",
                      scoreItemData.idcard || "",
                      scoreItemData.exam_rank || "",
                      scoreItemData.cert_sn || "",
                      activeItem.photo || "",
                      scoreItemData.exam_category,
                      scoreItemData.exam_date||""
                    );
                  }}
                >
                  点击预览
                </span>
              </p>
              <p className="division"></p>
              <p>
                纸质证书：
                {scoreItemData.pay_status_cert === 0 ? (
                  <span
                    className="line"
                    onClick={() =>
                      certPay(
                        scoreItemData.order_sn_cert,
                        scoreItemData.pay_status_cert
                      )
                    }
                  >
                    点击申请
                  </span>
                ) : (
                  "已支付"
                )}
              </p>
              {scoreItemData.pay_status_cert === 1 && (
                <>
                  <>
                  {/* <p>领取地点：{scoreItemData.detialAdress}</p> */}
                  {/* <p>联系方式：{scoreItemData.phoneNumber}</p> */}
                  <p>联系方式：18600753621</p>
                  </>
                </>
              )}
            </>
          )}
          <Button
            className="examScoreBtn"
            onClick={() => setCrtImgVisible(false)}
            color="primary"
            fill="solid"
            block
          >
            确定
          </Button>
        </div>
      </Mask>
    </div>
  );
};

export default MyExamList;
