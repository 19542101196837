import { Toast } from "antd-mobile"
import axios from "axios"
import Cookies from "js-cookie"

let baseUrl
if (process.env.NODE_ENV === 'development') {
	// 在开发环境下执行的代码
	baseUrl = 'http://127.0.0.1:8360'
} else if (process.env.NODE_ENV === 'production') {
	// 在生产环境下执行的代码
	baseUrl = 'https://api.bjsssst.com'
}


// baseUrl = 'https://api.bjsssst.com'



//const weixinBaseUrl = '/weChat'
const weixinBaseUrl = 'https://ssjy.bjsssst.com/weChat'
//const weixinBaseUrl = 'https://api.weixin.qq.com'
const baseLocal = ''
let show = () => { }
let hide = () => { }
export const outside = (_show = show, _hide = hide) => {
	show = _show
	hide = _hide
}
const Request = (params = {
	method: 'post',
	options: {}
}) => {
	return new Promise((r, j) => {
		let requestUrl = ''
		switch (params.urlType) {
			case 'weChat':
				requestUrl = `${weixinBaseUrl}${params.url}`;
				break
			case 'local':
				requestUrl = `${baseLocal}${params.url}`;
				break
			default:
				requestUrl = `${baseUrl}${params.url}`;
		}
		const axiosConfig = {
			url: requestUrl,
			method: params.method.toLocaleLowerCase(),
			headers: {
				'bjSport-Token': Cookies.get('requestToken')
			},
			...params.options
		}
		if (axiosConfig === 'get') {
			params.data && (axiosConfig.params = params.data)
		} else {
			params.data && (axiosConfig.data = params.data)
		}

		console.log(JSON.stringify(params), "params")
		show()
		axios(axiosConfig).then(res => {
			hide()
			if (res.data.errno || typeof res.data.status === 'string') {
				Toast.show(res.data.errmsg || res.data.status);
				j(res.data.errmsg || res.data.status)
				return
			}
			r(res.data)
		}, err => {
			console.log(err, "err")
			Toast.show({
				icon: "fail",
				content: err,
			});
			j(err)
			hide()
		})
	})
}

export default Request
