import { useEffect, useState, Fragment, useRef } from "react";
import {
  Image, 
  Form,
  Input,
  Button,
} from "antd-mobile";

import { useLocation } from "react-router-dom";
import copy from 'copy-to-clipboard';
import NoData from "../../components/noData/noData";
import getCloudImg from "../../unit/getCloudImg";
import getCloudDocumnet from "../../unit/getCloudDocumnet"
import Request from "../../unit/request";
import "./queryData.scss";
import qs from "qs";
import { Toast } from "antd-mobile";

const QueryData = () => {
  const [examList, setExamList] = useState([]);
  const [student, setStudent] = useState([]);



  const downloadCrt = () => {
    Request({
      url: "/admin/exam/getPayCertList",
      method: "post",
      data: {
      },
    }).then((res) => {

      if (res.data.msg) {
        Toast.show({
          content: res.data.msg
        });
        return
      }

      copy(res.data.url, {
        message: '点击确定复制下载链接',
      });

      Toast.show({
        content: `${res.data.url}:已复制下载链接，请前往浏览器打开自动下载`,
      });
    });
  }

  const getCertUrl = (cert_url) => {
    getCloudImg({
      key: "fileID",
      list: [{ fileID: cert_url }],
    }).then((list) => {
      console.log("11111", list)
      copy(list, {
        message: '点击确定复制下载链接',
      });
      Toast.show({
        content: '已复制下载链接，请前往浏览器打开自动下载',
      });
    });
  }



  

  const form = useRef(null);



  const submit = (e) => {
    const params = e;
    if(!params.name&&!params.name&&!params.phoneNumber){
      Toast.show({
        icon: "error",
        content: "请至少输入一个选项",
      });
      return 
    }
    Request({
      url: "/admin/user/selectInfo",
      method: "post",
      data: {
        name:params.name||"",
        idcard:params.idcard||"",
        phoneNumber:params.phoneNumber||""
      },
    }).then((res) => {
      if(res.errno=='1000'){
        Toast.show({
          icon: "error",
          content: res.msg,
        });
      }else{

        const list = res.data.filter((v) => v.userLevel === 1);
        getCloudImg({
          key: "photo",
          alias: "img",
          list,
        }).then((imgList) => {
          setStudent(imgList);
        });
      }
      
    });
  }

  return (
    <div className="examDetail">

      <Form
        ref={form}
        layout="horizontal"
        mode="card"
        // onValuesChange={formValuesChange}
        onFinish={submit}
        footer={
          <div>
            <Button block type="submit" color="primary" size="large">
              查询信息
            </Button>
          </div>
        }
      >
        <Form.Header>平台信息查询</Form.Header>
        <Form.Item label="学生姓名" name="name" required={false}>
          <Input placeholder="请输入学生姓名" />
        </Form.Item>
        <Form.Item label="身份证号" name="idcard" required={false}>
          <Input placeholder="请输入身份证号" />
        </Form.Item>
        <Form.Item label="手机号" name="phoneNumer" required={false}>
          <Input placeholder="请输入手机号" />
        </Form.Item>
      </Form>

   
      {student.map((v) => (
        <div className="studentListItem">
          <div
            className="studentItem"
            key={v.student_id}
          >
            <div className="studentLeft">
              <Image src={v.img} alt="" lazy/>
            </div>
            <div className="studentRight">
              <p>姓名：{v.name}</p>
              <p>性别：{v.gender === 1 ? "男" : v.gender === 2 ? "女" : "-"}</p>
              <p>生日：{v.birthday && v.birthday.split(" ")[0]}</p>
              <p>身份证：{v.idcard}</p>
              <p>手机号：{v.phoneNumber}</p>
              <p>学校：{v.school}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default QueryData;
