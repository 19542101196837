import React from 'react';
import './queryButton.scss'; // 导入按钮的样式文件

const RoundRectButton = ({ onClick, text }) => {
  return (
    <button className="round-rect-button" id='fixedButton' onClick={onClick}>
      <div className="semi-circle"></div>
      <div className="rectangle">
        <span>{text}</span>
      </div>
    </button>
  );
};

export default RoundRectButton;
